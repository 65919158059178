import {useEffect,useState} from 'react';
import { useNavigate,useParams } from "react-router-dom";

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';


import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { styled } from '@mui/material/styles';

import { convertToRaw,convertFromRaw, EditorState } from "draft-js";
//import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Fragment } from "react";

import parse from 'html-react-parser';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'
import ClientService from '../service/Client'
import ClientLogService from '../service/ClientLog'
import ClientMealLogService from '../service/ClientMealLog'
import ProjectService from '../service/Project'

const formatDateOfBirth=function(d){
	var d = new Date(d);
	Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}
    var dformat = [
               d.getDate().padLeft(),
			   (d.getMonth()+1).padLeft(),
               d.getFullYear()].join('-') ;
	return dformat;
}

const Blank = () => {
	
	let params = useParams();
	let client_id =params.id;
	let method =params.method;
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	const [projectLoaded,setProjectLoaded]=useState(false);
	
	const [clientData,setClientData]=useState([]);
	const [clientLoaded,setClientLoaded]=useState(false);
	
	const [methodType,setMethodType]=useState(method.charAt(0).toUpperCase() + method.slice(1));
	const [clientLogData,setClientLogData]=useState([]);
	
	const [tempData,setTempData]=useState(null);
	const [tempHtmlData,setHtmlTempData]=useState('');

		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
								
						
						}else{
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
								if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
									loadProjectById(project_id);
									
								}
								if(['New'].includes(methodType)){
									getClientTemplate();
									loadClientNextRef(project_id);
								}
								if(['View','Edit'].includes(methodType)){
									loadClientById(client_id);
									loadClientLogById(client_id);
								}
								
								
							}else if(account_type=='Researcher'){//Researcher
								if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
									loadProjectById(project_id);
									
								}
								if(['New'].includes(methodType)){
									getClientTemplate();
									loadClientNextRef(project_id);
								}
								if(['View','Edit'].includes(methodType)){
									loadClientById(client_id);
									loadClientLogById(client_id);
								}
							}
						}
					}
				}	
		}
	}, []);
	
		const oldContent ={"blocks":[{"key":"6vocr","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}};
		const blankContent ={"blocks":[{"key":"6vocr","text":"tesft","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}};

	const handleCreateClient = (e) => {
		
		console.log(tempData);
		if(tempData !== null) {
		   clientData.notes=JSON.stringify(tempData);
		}
		
		clientData.code=code;
		clientData.sex=sex;
		clientData.date_of_birth=dateOfBirth;
		clientData.age_group=ageGroup;
		clientData.weight=weight;
		clientData.height=height;
		clientData.consent_recieved=consentRecieved;
		clientData.created_by=currentUser._id;
		clientData.last_updated_by=currentUser._id;
		
		console.log('clientData',clientData);
		ClientService.create(clientData).then(function(res){
			console.log(res.data);
			var client=res.data;
			navigate("/client/view/"+client._id);
			navigate(0);//refresh page
			//loadClientById(client_id);
			
		}).catch(function(error){
			console.log(error);
		});
	};
	
	const handleUpdateClient = (e) => {
		console.log(tempData);
		if(tempData !== null) {
		   clientData.notes=JSON.stringify(tempData);
		}
		
		clientData.code=code;
		clientData.sex=sex;
		clientData.date_of_birth=dateOfBirth;
		clientData.age_group=ageGroup;
		clientData.weight=weight;
		clientData.height=height;
		clientData.consent_recieved=consentRecieved;
		clientData.created_by=currentUser._id;
		clientData.last_updated_by=currentUser._id;
		
		ClientService.update(clientData).then(function(res){
			console.log(res.data);
			
			navigate("/client/view/"+client_id);
			navigate(0);//refresh page
			//loadClientById(client_id);
			
		}).catch(function(error){
			console.log(error);
		});
	};
	
	function getClientTemplate(){
		var blankEditor ={"blocks":[{"key":"6vocr","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}};
		
		if(methodType=='New'){
			setEditorState(EditorState.createWithContent(convertFromRaw(blankEditor)));
			setClientData({'project':project_id,'sex':'M'});
		}
		
	}
	
	function loadClientNextRef(project_id){
			ClientService.getNextRef(project_id).then(function(res){
				setCode(res.data);
				console.log(res.data);
			}).catch(function(error){
					console.log(error);
			});
		}
	
	function loadProjectById(project_id){
			ProjectService.getById(project_id).then(function(res){
				setProjectData(res.data);
				console.log(res.data);
				setProjectFound(true);
				setProjectLoaded(true);
			}).catch(function(error){
					console.log(error);
					setProjectFound(false);
			});
		}
		
	function loadClientById(client_id){
			ClientService.getById(client_id).then(function(res){
				setClientData(res.data);
				console.log(res.data);
				
				console.log('search for client project',res.data.project._id);
				loadProjectById(res.data.project._id);
				setClientLoaded(true);
				console.log('method',method);
				
				var _client= res.data;
				setCode(_client.code);
				setSex(_client.sex);
				setDateOfBirth(_client.date_of_birth);
				setAgeGroup(_client.age_group);
				setWeight(_client.weight);
				setHeight(_client.height);
				setConsentRecieved(_client.consent_recieved);
				
				
				console.log('_client',_client);
				
				var clientNotes = res.data.notes;
				if(clientNotes==''){
					clientNotes={};
				}
				console.log('clientNotes',clientNotes);
				if(methodType=='Edit'){
					setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(clientNotes))));
				}
				if(methodType=='View'){
					 setHtmlTempData(draftToHtml(JSON.parse(clientNotes)));
				}
				
				
			}).catch(function(error){
					console.log(error);
			});
	}
/* client logs */		
	function loadClientLogById(client_id){
			ClientLogService.getByFilter({'client':client_id}).then(function(res){
				setClientLogData(res.data);
				console.log(res.data);
			}).catch(function(error){
					console.log(error);
			});
		}
		
		function createClientLog(client_id,clientLog){
			ClientLogService.create(clientLog).then(function(res){
				console.log(res.data);
				loadClientLogById(client_id)
			}).catch(function(error){
					console.log(error);
			});
		}
		
		function updateClientLog(clientLog){
			ClientLogService.update(clientLog).then(function(res){
				console.log(res.data);
				loadClientLogById(client_id)
			}).catch(function(error){
					console.log(error);
			});
		}
		
		/*client meal log */
		function loadClientMealLogById(client_log_id){
			ClientMealLogService.getByFilter({'client_log_id':client_log_id}).then(function(res){
				//setClientLogData(res.data);
				console.log(res.data);
			}).catch(function(error){
					console.log(error);
			});
		}
		
		function createClientMealLog(client_log_id,clientMealLog){
			ClientMealLogService.create(clientMealLog).then(function(res){
				console.log(res.data);
				loadClientMealLogById(client_log_id)
			}).catch(function(error){
					console.log(error);
			});
		}
		
		function updateClientMealLog(client_log_id,clientMealLog){
			ClientMealLogService.update(clientLog).then(function(res){
				console.log(res.data);
				loadClientMealLogById(client_log_id);
			}).catch(function(error){
					console.log(error);
			});
		}
		
	const handleCreateClientLog = (e) => {
		var clientLog={'client':client_id,'completed':true,'date':'2024-01-02','created_by':AuthService.getCurrentUser()._id};
		console.log(clientLog);
		createClientLog(client_id,clientLog);
	};
	//handleUpdateClientLog	
	const handleUpdateClientLog = (e,clientLog) => {
		clientLog.name='I chaged it to some thing else';
		updateClientLog(clientLog);
	};
	
	
	//https://codesandbox.io/p/sandbox/react-nqf61?file=%2Fsrc%2FreactDraft%2Findex.js%3A22%2C4-46%2C8
	//	https://stackoverflow.com/questions/71095531/draft-js-json-to-editorstate-does-not-update
	const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(oldContent)));
  const onEditorStateChange = function (editorState) {
    setEditorState(editorState);
	const content = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
	console.log(content);
	setTempData(convertToRaw(editorState.getCurrentContent()));
  };
  
  const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: '#ccc',
  padding: theme.spacing(1),

}));

	const jsonData =function(obj){
		return JSON.stringify(obj)
	} 

	const generateBreadcrumbs=function(){
		if(methodType=="New"){
			return <Typography color="text.primary">{methodType}</Typography>
		}else{
			return <Typography color="text.primary">{methodType}</Typography>
		}
	}	

	const [code, setCode] = useState('');
	const handleCodeChange = (event) => {
		setCode(event.target.value);
	};
	
	//sex
	const [sex, setSex] = useState('');
	const handleSexChange = (event) => {
		setSex(event.target.value);
	};
	//date of birth
	const [dateOfBirth, setDateOfBirth] = useState('');
	const handleDateOfBirthChange = (event) => {
		console.log(event.$d);
		setDateOfBirth(event.$d);
	};
	//age range
	const [ageGroup, setAgeGroup] = useState('');
	const handleAgeGroupChange = (event) => {
		setAgeGroup(event.target.value);
	};
		
	//Weight
	const [weight,setWeight] = useState(0.0);
	const handleWeightChange = (event) => {
		setWeight(event.target.value);
	};
	
	//Height
	const [height, setHeight] = useState(0.0);
	const handleHeightChange = (event) => {
		setHeight(event.target.value);
	};
	//consent Recieved
	const [consentRecieved, setConsentRecieved] = useState(false);
	const handleConsentRecievedChange = (event) => {
		setConsentRecieved(event.target.checked);
	}
	
	//Dietary preferences
  const [state, setState] = useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
	console.log(state);
  };

  const { gilad, jason, antoine } = state;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	<br/>
	
	{/*
	<h3>Dietary preferences</h3>
	 <Box sx={{ display: 'flex' }}>
	 
      <FormControl sx={{ m:0 }} component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={gilad} onChange={handleChange} name="gilad" />
            }
            label="Gilad Gray"
          />
          <FormControlLabel
            control={
              <Checkbox checked={jason} onChange={handleChange} name="jason" />
            }
            label="Jason Killian"
          />
          <FormControlLabel
            control={
              <Checkbox checked={antoine} onChange={handleChange} name="antoine" />
            }
            label="Antoine Llorca"
          />
		  <FormControlLabel
            control={
              <Checkbox checked={antoine} onChange={handleChange} name="antoine" />
            }
            label="Antoine Llorca"
          />
        </FormGroup>
        <FormHelperText></FormHelperText>
      </FormControl>
    </Box>*/}
	
	{(projectFound) ?
	<>
	<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/"> Home </Link>
		<Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>

		 <Link underline="hover" color="inherit" href={`/clients/project/${projectData._id}`} > Clients  </Link>
		<Typography color="text.primary">{methodType} {clientData.code}</Typography>
      </Breadcrumbs>
	
	</>
	:<>
	<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/"> Home </Link>
		 <Link underline="hover" color="inherit" href="/clients" > Clients  </Link>
		<Typography color="text.primary">{methodType} {clientData.code}</Typography>
      </Breadcrumbs>
	</>}
	  
	  <br/>
	  <Divider />
		<br/>
		
	{/* New */}
	{(methodType=='New') ?
		<>
		
	<br/>
	<Div>{"General Infromation"}</Div>
	<br/>
	
	<div style={{ width: '100%' }}>
		  <Box
			sx={{
			  display: 'flex',
			  flexDirection: 'row-reverse',
			  p: 1,
			  m: 1,
			//  bgcolor: 'background.paper',
			  borderRadius: 1,
			}}
		  >
		<Button onClick={(e) => handleCreateClient(e)} variant="contained" color="primary">
			save
		</Button>
			  </Box>
	  </div>
	

		<br/>
	
		   
		     <TextField
				 type="text" 
				  label="Participant ID"
				  id="outlined-end-adornment"
				  sx={{  width: '500px' }}
				  value={code}
				  onChange={handleCodeChange}
				  
				/>
		<br/><br/>
		
		
		  {(projectLoaded)?
		  <>
		  <FormControl  sx={{minWidth:'500px'}} >
			<InputLabel id="demo-simple-select-label">Sex</InputLabel>
			<Select
			  labelId="demo-simple-select-label"
			  id="demo-simple-select"
			  value={sex}
			  label="Sex"
			  onChange={handleSexChange}
			>
			  <MenuItem value="Male">Male</MenuItem>
			  <MenuItem value="Female">Female</MenuItem>
			  <MenuItem value="Other">Other</MenuItem>
			</Select>
		  </FormControl>
		  
		  <br/><br/>
		  
		
		  
		  
		  
		  {/* Age view : Date Of Birth */}
		   {(projectData.settings.age_view=='date_of_birth') ?
		   <>{/*{jsonData(dateOfBirth)}*/}
			<LocalizationProvider dateAdapter={AdapterDayjs}>
			  <DemoContainer   components={['DatePicker']}>
				<DatePicker sx={{width:'500px'}} disableFuture value={dayjs(dateOfBirth)} format="DD-MM-YYYY"  onChange={handleDateOfBirthChange}  label="Date Of Birth" />
			  </DemoContainer>
			</LocalizationProvider>
			<br/>
			</>:<></>}
		   
		   {/* Age view : AGE Group*/}
		   {(projectData.settings.age_view=='age_group')?
			<>
			<FormControl sx={{minWidth:'500px'}} >
			<InputLabel id="demo-simple-select-label">Age Group</InputLabel>
			<Select
			  labelId="demo-simple-select-label"
			  id="demo-simple-select"
			  value={ageGroup}
			  label="Age Group"
			  onChange={handleAgeGroupChange}
			>
			  <MenuItem value="18-24">18-24</MenuItem>
			  <MenuItem value="25-34">25-34</MenuItem>
			  <MenuItem value="35-44">35-44</MenuItem>
			  <MenuItem value="45-54">45-54</MenuItem>
			  <MenuItem value="55-64">55-64</MenuItem>
			  <MenuItem value="65 and over">65 and over</MenuItem>
			</Select>
		  </FormControl>
		  
		  <br/><br/>
			</>:<></>}
			
			
			<TextField
				 type="number" 
				  label="Weight"
				  id="outlined-end-adornment"
				  sx={{  width: '500px' }}
				  value={weight}
				  onChange={handleWeightChange}
				  InputProps={{
					endAdornment: <InputAdornment position="end">kg</InputAdornment>,
				  }}
				/>
		<br/><br/>
		<TextField
			 type="number" 
			  label="Height"
			  id="outlined-end-adornment"
			  sx={{  width: '500px' }}
			  value={height}
			  onChange={handleHeightChange}
			  InputProps={{
				endAdornment: <InputAdornment position="end">meters</InputAdornment>,
			  }}
			/>
		<br/><br/>
		
		<FormGroup>
		  <FormControlLabel onChange={handleConsentRecievedChange} control={<Checkbox checked={consentRecieved} />} label="Consent received" />
		</FormGroup>
		  

		  </>:
		  <>not loaded</>
		  
		  }
		 

	
		<h3>Notes</h3>
		<Editor 
		editorStyle={{ border: "1px solid grey" ,backgroundColor:"white",minHeight:"200px"}}
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
		/>

		<br/><br/>
      
	  
		</>
	:<></>}

	
	{/* View */}
	{(methodType=='View') ?
		<>
		<Div>{"General Infromation"}</Div>
		
			<br/>
			
	<div style={{ width: '100%' }}>
		  <Box
			sx={{
			  display: 'flex',
			  flexDirection: 'row-reverse',
			  p: 1,
			  m: 1,
			//  bgcolor: 'background.paper',
			  borderRadius: 1,
			}}
		  >
			  <Button  href={`/client/edit/${clientData._id}`}  variant="contained" color="primary">
				Edit
				</Button>
			  </Box>
	  </div>
	  
	  {/*}  
	<table width="100%" style={{cellPadding:'3px'}}>
	  <tbody>
	  <tr>
		<td><h5>Participant ID</h5></td>
		<td>{clientData.code}</td>
		<td><h5>Sex</h5></td>
		<td>
		
		</td>
		<td><h5>Weight</h5></td>
		<td><h5>Height</h5></td>
		<td><h5>Consent Recieved</h5></td>
	  </tr>
	  </tbody>
	</table>*/}

		<h3>Participant ID</h3>
		{clientData.code}
		<h3>Sex</h3>
		{clientData.sex}
		
		{(projectLoaded)?
		 <>
		
			{/* Age view : Date Of Birth */}
			   {(projectData.settings.age_view=='date_of_birth') ?
			   <>
			   <h3>Date Of Birth</h3>
			   {formatDateOfBirth(clientData.date_of_birth)} (aged {clientData.age})
				</>:<></>}
			   
			   {/* Age view : AGE Group*/}
			   {(projectData.settings.age_view=='age_group')?
				<>
			   <h3>Age Group</h3>
			   {clientData.age_group}
				</>:<></>}
			
		</>:<></>}
		
		<h3>Weight</h3>
		{clientData.weight} kg
		<h3>Height</h3>
		{clientData.height} meters
		<h3>Consent Recieved</h3>
		{(clientData.consent_recieved) ?
		<>
		{/*<CheckIcon />*/}
			Yes
		</>
		:<>
			{/*<ClearIcon />*/}
			No
		</>}
		<h3>Notes</h3>
		
		{parse(tempHtmlData)}
		<Div>{"Food Logs"}</Div>
		<br/>
		<Button  href={`/client/logs/${clientData._id}`}  variant="contained" color="primary">
				See Logs
				</Button><br/>
				{/*<Button  onClick={(e) => handleCreateClientLog(e)}  variant="contained" color="primary">
			Test add new log
	</Button>*/}
		
		
		
		{/*	<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
	{clientLogData.map((row,index) => (
      <ListItem
	  key={row._id}
	  disableGutters
          secondaryAction={
            <IconButton onClick={(e) => handleUpdateClientLog(e,row)}  aria-label="comment">
              <ModeEditIcon />
            </IconButton>
          }
	  >
        <ListItemAvatar>
          <Avatar>
            <FastfoodIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={row.name}  secondary={row.date} />
      </ListItem>
	  ))}
    </List>*/}
	{/*<Div>{"Questionnaires"}</Div>*/}
		<br/>
		
		<br/><br/>
		
		
		</>
	:<></>}
	
	
	{/* Edit */}
	{(methodType=='Edit') ?
		<>
		
	<br/>
		<Div>{"General Infromation"}</Div>
	<br/>
	
	<div style={{ width: '100%' }}>
		  <Box
			sx={{
			  display: 'flex',
			  flexDirection: 'row-reverse',
			  p: 1,
			  m: 1,
			//  bgcolor: 'background.paper',
			  borderRadius: 1,
			}}
		  >
			<Button onClick={(e) => handleUpdateClient(e)} variant="contained" color="primary">
			save
		</Button>
			  </Box>
	  </div>
	
		     <TextField
				 type="text" 
				  label="Participant ID"
				  id="outlined-end-adornment"
				  sx={{  width: '500px' }}
				  value={code}
				  onChange={handleCodeChange}
				  
				/>
		<br/><br/>
		
		
		  {(projectLoaded)?
		  <>
		  <FormControl  sx={{minWidth:'500px'}} >
			<InputLabel id="demo-simple-select-label">Sex</InputLabel>
			<Select
			  labelId="demo-simple-select-label"
			  id="demo-simple-select"
			  value={sex}
			  label="Sex"
			  onChange={handleSexChange}
			>
			  <MenuItem value="Male">Male</MenuItem>
			  <MenuItem value="Female">Female</MenuItem>
			  <MenuItem value="Other">Other</MenuItem>
			</Select>
		  </FormControl>
		  
		  <br/><br/>
		  
		
		  
		  
		  
		  {/* Age view : Date Of Birth */}
		   {(projectData.settings.age_view=='date_of_birth') ?
		   <>{/*{jsonData(dateOfBirth)}*/}
			<LocalizationProvider dateAdapter={AdapterDayjs}>
			  <DemoContainer   components={['DatePicker']}>
				<DatePicker sx={{width:'500px'}} disableFuture value={dayjs(dateOfBirth)} format="DD-MM-YYYY"  onChange={handleDateOfBirthChange}  label="Date Of Birth" />
			  </DemoContainer>
			</LocalizationProvider>
			<br/>
			</>:<></>}
		   
		   {/* Age view : AGE Group*/}
		   {(projectData.settings.age_view=='age_group')?
			<>
			<FormControl sx={{minWidth:'500px'}} >
			<InputLabel id="demo-simple-select-label">Age Group</InputLabel>
			<Select
			  labelId="demo-simple-select-label"
			  id="demo-simple-select"
			  value={ageGroup}
			  label="Age Group"
			  onChange={handleAgeGroupChange}
			>
			  <MenuItem value="18-24">18-24</MenuItem>
			  <MenuItem value="25-34">25-34</MenuItem>
			  <MenuItem value="35-44">35-44</MenuItem>
			  <MenuItem value="45-54">45-54</MenuItem>
			  <MenuItem value="55-64">55-64</MenuItem>
			  <MenuItem value="65 and over">65 and over</MenuItem>
			</Select>
		  </FormControl>
		  
		  <br/><br/>
			</>:<></>}
			
			
			<TextField
				 type="number" 
				  label="Weight"
				  id="outlined-end-adornment"
				  sx={{  width: '500px' }}
				  value={weight}
				  onChange={handleWeightChange}
				  InputProps={{
					endAdornment: <InputAdornment position="end">kg</InputAdornment>,
				  }}
				/>
		<br/><br/>
		<TextField
			 type="number" 
			  label="Height"
			  id="outlined-end-adornment"
			  sx={{  width: '500px' }}
			  value={height}
			  onChange={handleHeightChange}
			  InputProps={{
				endAdornment: <InputAdornment position="end">meters</InputAdornment>,
			  }}
			/>
		<br/><br/>
		
		<FormGroup>
		  <FormControlLabel onChange={handleConsentRecievedChange} control={<Checkbox checked={consentRecieved} />} label="Consent received" />
		</FormGroup>
		  

		  </>:
		  <>not loaded</>
		  
		  }
		  

		
		
		<h3>Notes</h3>
		<Editor
		editorStyle={{ border: "1px solid grey" ,backgroundColor:"white",minHeight:"200px"}}
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
		/>

		
      
	  
		</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Blank;