import axios from "axios"
var Config = require('../config.json')
import AuthService from '../service/auth'
var entity='Admin';

const AccountService = {

    getAll: function() {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity,header);
    },
    getById: function(id) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity+'/'+id,header);
    },
    update: function(account) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.put(Config.api_url+'/'+entity+'/'+account._id,account,header);
    },
    deleteById: function(id) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.delete(Config.api_url+'/'+entity+'/'+id,header);
    }
};

export default AccountService;