import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import {useEffect,useState} from 'react';
import { useNavigate , useParams} from "react-router-dom";

import TopPrivateNavigationRestricted from '../components/TopPrivateNavigationRestricted.jsx'

//services
import AuthService from '../service/auth'
import AdminAccountService from '../service/AdminAccount'
import MasterAccountService from '../service/MasterAccount'
import AccountRequestService from '../service/AccountRequest';

const Restricted = () => {
	
	let params = useParams();
	let str =params.error;
	console.log('str',str);	 
	var decodedString = atob(str); // Base64 decode the String
	var urlParams = new URLSearchParams(decodedString);
	console.log(urlParams); 
	var type = urlParams.get('type');
	console.log('type',type); 

	const [errorType,setErrorType]=useState({'type':type});

	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	
	const [ShowErrorMessage,setErrorStatus]=useState(false); 
	const [ErrorText,setErrorMessage]=useState(""); 
	
	const [ShowSuccessMessage,setSuccessStatus]=useState(false); 
	const [SuccessText,setSuccessMessage]=useState("");
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			//navigate("/login");
		}else{
			setCurrentUser(AuthService.getCurrentUser());
			
		}
		
		
		
	}, []);
	
	const handleResendVerificationEmail = () => {
		console.log('email verification resend for id:'+AuthService.getCurrentUser()._id);
		setErrorStatus(false);
		setErrorMessage("");
		setSuccessStatus(false);
		setSuccessMessage("");
		
		AuthService.resendVerifyEmail({'_id':AuthService.getCurrentUser()._id,'account_type':AuthService.getCurrentUser().__t}).then(function(res){
				console.log(res.data);
				setSuccessStatus(true);
				setSuccessMessage("Request sent to resend your email verification, Please check your emails.");
				
			}).catch(function(error){
				console.log(error);
				setErrorStatus(true);
				setErrorMessage("There was an error!! Please try again later");
	
			});
	};
	
	const handleContactAdmin = () => {
		console.log('contact admin :'+AuthService.getCurrentUser()._id);
	};
	
  return(
  <>
	<TopPrivateNavigationRestricted/>
		<br/>
		{(errorType.type=='expired') ?
		<>
			
				<Typography variant="h5" gutterBottom>
					Your account has expired!!Please contact Admin
				</Typography>
				
				{/*	<Button onClick={handleContactAdmin} >Contact Admin</Button>
		*/}
			  
			
		</>	
		:<></>}
		{(errorType.type=='emaiverification') ?
		<>
				<Typography variant="h5" gutterBottom>
					Email verification error 
				</Typography>				
				{ShowErrorMessage &&
					<Alert variant="outlined" severity="error">{ErrorText}</Alert>
				} 
				{ ShowSuccessMessage &&
					<Alert variant="outlined" severity="success">{SuccessText}</Alert>
				}
				<br/>
				<Button variant="outlined" onClick={handleResendVerificationEmail} >Resend email verification link</Button>
			  <br/>
			 
			
			
		</>	
		:<></>}

	</>
  ) 
  ;
};

export default Restricted;