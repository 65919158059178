import {useEffect,useState} from 'react';
import { useNavigate ,useParams} from "react-router-dom";


import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
//import FormControl from '@mui/material/FormControl';
//import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'
import FoodService from '../service/Food'
import ProjectService from '../service/Project'

const formatJson=function(obj){
	return JSON.stringify(obj);
}


const Foods = () => {
	
	//let params = useParams();
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	
	const [FoodData,setFoodData]=useState([]);
	const [FoodDataLoaded,setFoodDataLoaded]=useState(false);
	
	function loadFoodData(){
		FoodService.getAll().then(function(res){
			setFoodData(res.data);
			setFoodDataLoaded(true);
			console.log(res.data);
			console.log('FoodDataLoaded',FoodDataLoaded);
			console.log(res.data);
		});
	}
	
	function loadFoodDataByFilter(filter){
		FoodService.getByFilter(filter).then(function(res){
			setFoodData(res.data);
			setFoodDataLoaded(true);
			setFilteredFoodData(res.data);
			console.log(res.data);
			console.log('FoodDataLoaded',FoodDataLoaded);
		});
	}
	
	const renderDetailsButton = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 0 }}
                    onClick={() => {
                        moreInfo(params.row)
                    }}
                >
                    More Info
                </Button>
            </strong>
        )
    }
	
	
	const renderCloneButton = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 0 }}
                    onClick={() => {
                        //cloneFood(params.row)
						navigate("/food/clone/"+params.row._id);
                    }}
                >
                   Clone
                </Button>
            </strong>
        )
    }
	
	const renderCreater = (params) => {
        return (
            <strong>
			{(params.row.created_by!==null)?<>{params.row.created_by.first_name} {params.row.created_by.last_name} ({params.row.created_by.__t})</>:<>Unknown</>}
            </strong>
        )
    }
	
	const columns = [
	  { field: 'code', headerName: 'CODE', width: 130 },
	  { field: 'name', headerName: 'Name', width: 500 },
	  { field: 'approved', headerName: 'Approved', width: 100 },
	 {
			field: '2',
			headerName: 'Created By',
			//width: 150,
			renderCell: renderCreater,
			disableClickEventBubbling: true,
		},
	 {
			field: '3',
			headerName: '',
			//width: 150,
			renderCell: renderDetailsButton,
			disableClickEventBubbling: true,
		},{
			field: '4',
			headerName: '',
			//width: 150,
			renderCell: renderCloneButton,
			disableClickEventBubbling: true,
		}
	];

	const moreInfo = (item) => {
		console.log('food',item);
		navigate("/food/view/"+item._id);
	};
	
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
								
						
						}else{
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								loadProjectById(project_id);
								///loadFoodDataByFilter({'project':project_id});
								//loadFoodDataByFilter({$or:true,'global':true,'project':project_id});
								loadFoodDataByFilter({'global':false,'project':project_id});
								//loadFoodData();
							}else{
								//loadFoodData();
								loadFoodDataByFilter({'global':true});
							}
							//load
							if(account_type=='Admin'){//Admin
								//loadFoodData();
							}else if(account_type=='Master'){//Master
								//loadFoodData();
								
							}else if(account_type=='Researcher'){//Researcher
								//loadFoodData();
							}
						}
					}
				}	
		}
	}, []);
	
		function loadProjectById(project_id){
			ProjectService.getById(project_id).then(function(res){
				setProjectData(res.data);
				console.log(res.data);
				setProjectFound(true);
			}).catch(function(error){
					console.log(error);
					setProjectFound(false);
			});
		}
	
	const [FilteredFoodData,setFilteredFoodData]=useState([]);
	const handleFoodSearchChange = (event) => {
		setFilteredFoodData([]);
		var filteredList=[];
		console.log(event.target.value);
		var keyword =event.target.value
		if(keyword.length==0){	
			setFilteredFoodData(FoodData);
		}
		if(keyword.length>1){
			for(var i=0;i<FoodData.length;i++){
			//if(FoodData[i].name)
				if(FoodData[i].name.toLowerCase().includes(keyword.toLowerCase()) || FoodData[i].code.toLowerCase().includes(keyword.toLowerCase())){
					filteredList.push(FoodData[i]);
				}
			
		}
		setFilteredFoodData(filteredList);
			
		}
		
	}
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
<br/>
	{(projectFound) ?
	<>
	<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/"> Home </Link>
		<Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>

		 <Link underline="hover" color="inherit" href={`/foods`} > Foods  </Link>
		{/*<Typography color="text.primary">test</Typography>*/}
      </Breadcrumbs>
	
	</>
	:<>
	<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/"> Home </Link>
		 <Link underline="hover" color="inherit" href="/foods" > Foods  </Link>
		{/*<Typography color="text.primary">test</Typography>*/}
      </Breadcrumbs>
	</>}
	
	  
	<br/>
	  <Divider />
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
		<div style={{ width: '100%' }}>
		<Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 1,
          m: 1,
      //    bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
	  
		
		<Button href={`/food/new`}  variant="contained" endIcon={<AddIcon />} >
			Create New Food 
	</Button>
	
	
		  	<Button href={`/upload/food`}  variant="outlined" endIcon={<UploadIcon />} >
			Upload data
		</Button>

      </Box>
    </div>
	 

		<br/>
	{(FoodDataLoaded) ?
	<>
		<br/>
		
		{(FoodData.length>0) ?
				
			<div style={{ height: 800, width: '100%' }}>
			
			<TextField fullWidth onChange={(e) => {handleFoodSearchChange(e)}}
			
			id="outlined-basic" 
			label="Search by name or code.." 
			variant="outlined"
			InputProps={{
					  startAdornment: (
						<InputAdornment position="start">
						  <SearchIcon />
						</InputAdornment>
					  ),
					}}			
			
			/>
						<br/><br/>{FilteredFoodData.length} found.
						
						
						<br/><br/>
						
		  <DataGrid
			rows={FilteredFoodData}
			columns={columns}
			initialState={{
			  pagination: {
				paginationModel: { page: 0, pageSize: 15},
			  },
			}}
			pageSizeOptions={[5, 10,15,20]}
		   
		checkboxSelection={false}
		  />
		</div>
			:<>None found!!!
			
			<br/>
			
			
			</>}

	
	<br/> <br/><br/> <br/>
	</>
	:<>
	
	 <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
	
	
	</>}
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	<br/>
	 <Button href={`/food/new`}  variant="contained" endIcon={<AddIcon />} >
			Request New Food 
		</Button>
		<br/><br/>
		{(FoodDataLoaded) ?
	<>
		<br/>
		
		{(FoodData.length>0) ?
				
			<div style={{ height: 800, width: '100%' }}>
			
			<TextField fullWidth onChange={(e) => {handleFoodSearchChange(e)}}
			
			id="outlined-basic" 
			label="Search by name or code.." 
			variant="outlined"
			InputProps={{
					  startAdornment: (
						<InputAdornment position="start">
						  <SearchIcon />
						</InputAdornment>
					  ),
					}}			
			
			/>
						<br/><br/>{FilteredFoodData.length} found.
						
						
						<br/><br/>
						
		  <DataGrid
			rows={FilteredFoodData}
			columns={columns}
			initialState={{
			  pagination: {
				paginationModel: { page: 0, pageSize: 15},
			  },
			}}
			pageSizeOptions={[5, 10,15,20]}
		   
		checkboxSelection={false}
		  />
		</div>
			:<>None found!!!
			
			<br/>
			
			
			</>}

	
	<br/> <br/><br/> <br/>
	</>
	:<>
	
	 <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
	
	
	</>}
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Foods;