import axios from "axios"
var Config = require('../config.json')
import AuthService from '../service/auth'
var entity='Project';
var session_name= Config.app_name+'_'+Config.account_type;

const ProjectService = {
	//local
	setCurrentProject:function(project){
		console.log('project',project);
		localStorage.setItem(session_name+"_isProjectSelected", true);
		localStorage.setItem(session_name+"_selectedProject", JSON.stringify(project));
	},
	isProjectSelected:function(){
		return localStorage.getItem(session_name+"_isProjectSelected");
	},
	getCurrentProject:function(){
		return JSON.parse(localStorage.getItem(session_name+"_selectedProject"));
	},
	clearCurrentProject:function(){
		localStorage.removeItem(session_name+"_isProjectSelected");
		localStorage.removeItem(session_name+"_selectedProject");
	},
	//api
    create: function(project) {
	var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.post(Config.api_url+'/'+entity,project,header);
    },
    getAll: function() {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity,header);
    },
    getById: function(id) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity+'/'+id,header);
    },
    getByFilter: function(filter) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		var qs = serialize(filter);
		return axios.get(Config.api_url+'/'+entity+'/filter/params?'+qs,header);
    },
    update: function(request) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.put(Config.api_url+'/'+entity+'/'+request._id,request,header);
    },
    deleteById: function(id) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.delete(Config.api_url+'/'+entity+'/'+id,header);
    }
};

function serialize(obj) {
	var str = [];
	for (var p in obj)
	if (obj.hasOwnProperty(p)) {
		str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
	}
	return str.join("&");
}
		
export default ProjectService;