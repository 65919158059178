import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

var Config = require('../config.json');
import AuthService from '../service/auth'

import {useEffect,useState} from 'react';
import { useParams,useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';

import TopPublicNavigation from '../components/TopPublicNavigation.jsx'

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const ResetPassword=()=>{ 
	const navigate = useNavigate();
	
	const [ShowErrorMessage,setErrorStatus]=useState(false); 
	const [ErrorText,setErrorMessage]=useState(""); 
	
	const [ShowSuccessMessage,setSuccessStatus]=useState(false); 
	const [SuccessText,setSuccessMessage]=useState("");
	
	const [AccountType,setAccountType]=useState("Master");

	useEffect(() => {
		////logout
		//localStorage.clear();
		AuthService.clearCurrentUser()
		
		//set account type from config
		setAccountType(Config.account_type);
		
	}, []);
	
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		console.log({
		  email: data.get('email'),
		  password: data.get('password'),
		});
		resetPassword({'email':data.get('email'),'account_type':AccountType});
	};
	
	const [serviceData,setServiceData]=useState([]);
	function resetPassword(credentials){
			console.log('email',credentials.email);
			setErrorStatus(false);
			AuthService.resetPassword(credentials).then(function(res){
				console.log(res.data);
				setSuccessStatus(true);
				setSuccessMessage('If an account exists with this email , you will recieve an email with new password!!');
			
			}).catch(function(error){
				console.log(error);
				setSuccessStatus(true);
				setSuccessMessage('If an account exists with this email , you will recieve an email with new password!!');
			});
	}
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

		<TopPublicNavigation Linked={true} LinkTo={'/forgottenpassword'} />
          <Typography component="h1" variant="h5">
            Reset {AccountType} Account password
          </Typography>
		  	 
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
			{ ShowErrorMessage &&
				<Alert variant="filled" severity="error">{ErrorText}</Alert>
			} 
			{ ShowSuccessMessage &&
				<Alert variant="filled" severity="success">{SuccessText}</Alert>
			}
			
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
           
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/login" variant="body2">
                  Already have an account,Sign in?
                </Link>
              </Grid>
              <Grid item>
                
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default ResetPassword;