import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import "./index.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";

import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgottenPassword from "./pages/ForgottenPassword";
import VerifyEmail from "./pages/VerifyEmail";
import Account from "./pages/Account";

import Welcome from "./pages/Welcome";
import Home from "./pages/Home";
import Upload from "./pages/Upload";
import Project from "./pages/Project";
import Projects from "./pages/Projects";
import Clients from "./pages/Clients";
import Client from "./pages/Client";

import ClientLogs from "./pages/ClientLogs";
import ClientLog from "./pages/ClientLog";

import Test from "./pages/Test";
import Notifications from "./pages/Notifications";
import Settings from "./pages/Settings";

import Foods from "./pages/Foods";
import Food from "./pages/Food";

import Recipes from "./pages/Recipes";
import Recipe from "./pages/Recipe";

import Researchers from "./pages/Researchers";
import Researcher from "./pages/Researcher";

import Exports from "./pages/Exports";

import Blank from "./pages/Blank";
import Info from "./pages/Info";

import NoPage from "./pages/NoPage";
import Restricted from "./pages/Restricted";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
		  <Route path="/" element={<Layout />}>
          <Route path="/" index element={<Home />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/login"  element={<Login />} />
          <Route path="/register"  element={<Register />} />
          <Route path="/forgottenpassword"  element={<ForgottenPassword />} />
          <Route path="/verifyemail"  element={<VerifyEmail />} />
          <Route path="/account"  element={<Account />} />
          <Route path="/home" element={<Home />} />
          
		  <Route path="/upload/:table_name" element={<Upload />} />
          
		  <Route path="/client/:method/:id" element={<Client />} />
		  <Route path="/client/:method/:id/project/:project_id" element={<Client />} />
			
		  <Route path="/client/logs/:client_id" element={<ClientLogs />} />
		  <Route path="/client/log/:client_log_id" element={<ClientLog />} />
		  
		  <Route path="/clients" element={<Clients />} />
		  <Route path="/clients/project/:project_id" element={<Clients />} />

		  <Route path="/foods" element={<Foods />} />
		  <Route path="/food/:method/:id" element={<Food />} />
		  <Route path="/food/:method" element={<Food />} />
		  
		  <Route path="/recipes" element={<Recipes />} />
		  <Route path="/recipe/:method/:id" element={<Recipe />} />
		  <Route path="/recipe/:method" element={<Recipe />} />
		  
		  <Route path="/researchers" element={<Researchers />} />
		  <Route path="/researcher/:id" element={<Researcher />} />
		  
		  <Route path="/projects" element={<Projects />} />
		  <Route path="/project/:id" element={<Project />} />
		  <Route path="/project/settings/:id" element={<Settings />} />
		  
		  <Route path="/exports" element={<Exports />} />
		  <Route path="/exports/:table" element={<Exports />} />
		  <Route path="/exports/:table/:project_id" element={<Exports />} />
		  
		  <Route path="/notifications" element={<Notifications />} />
		  
          <Route path="/test" element={<Test />} />
          <Route path="/blank" element={<Blank />} />
          <Route path="/info" element={<Info />} />
		  
          <Route path="/restricted/:error" element={<Restricted />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

	<App />
);