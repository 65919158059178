import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

var Config = require('../config.json');
import AccountRequestService from '../service/AccountRequest';

import {useEffect,useState} from 'react';
import { useParams,useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';

import TopPublicNavigation from '../components/TopPublicNavigation.jsx'

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const Welcome=()=>{ 
	const navigate = useNavigate();
	
	const [ShowErrorMessage,setErrorStatus]=useState(false); 
	const [ErrorText,setErrorMessage]=useState(""); 
	
	const [ShowSuccessMessage,setSuccessStatus]=useState(false); 
	const [SuccessText,setSuccessMessage]=useState("");
	
	const [AccountType,setAccountType]=useState("");

	useEffect(() => {
		
		//set account type from config
		setAccountType(Config.account_type);
		
	}, []);
	
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		var request={'email':data.get('email'),
							'organisation':data.get('organisation'),
							'first_name':data.get('first_name'),
							'last_name':data.get('last_name'),
							'phone_number':data.get('phone_number'),
							'request_text':data.get('request_text'),
							'account_type':AccountType,'request_type':'Requested'};///requested means requested by user submitting form as oppose to been invited
		console.log('request',request);
							
		// validate fields
		const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		if((emailRegexp.test(request.email))&&(request.organisation)&&(request.first_name)&&(request.last_name)&&(request.request_text)){	
			sendAccountRequest(request);
		}else{
			setErrorStatus(true);
			setErrorMessage("Please ensure all required fields are completed correctly!!");
		}						
	};
	
	const [serviceData,setServiceData]=useState([]);
	function sendAccountRequest(request){
			setErrorStatus(false);
			setErrorMessage("");
			setSuccessStatus(false);
			setSuccessMessage("");
			AccountRequestService.createRequest(request).then(function(res){
				console.log(res.data);
				setServiceData(res);
				setSuccessStatus(true);
				setSuccessMessage("Request sent!!");
			}).catch(function(error){
				console.log(error);
				setErrorStatus(true);
				setErrorMessage("error: "+error.response.data.message);
			});
	}

  return (

    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
		<TopPublicNavigation  Linked={true}  LinkTo={'/welcome'} />
          <Typography component="h1" variant="h5">
		  Request {AccountType} Account
          </Typography>
		  	   {/* Master only */}
			{(AccountType=='Master') ?
			<>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
		  { ShowErrorMessage &&
				<Alert variant="filled" severity="error">{ErrorText}</Alert>
			} 
			{ ShowSuccessMessage &&
				<Alert variant="filled" severity="success">{SuccessText}</Alert>
			}
			
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
			
			<TextField
              margin="normal"
              required
              fullWidth
              id="organisation"
              label="Organisation/Affiliation"
              name="organisation"
              autoComplete="organisation"
            />
			
			<Grid container spacing={2}>
              <Grid item xs>
				<TextField
				  margin="normal"
				  required
				  fullWidth
				  id="first_name"
				  label="First Name"
				  name="first_name"
				  autoComplete="first_name"
				/>
              </Grid>
              <Grid item>
				<TextField
				  margin="normal"
				  required
				  fullWidth
				  id="last_name"
				  label="Last Name"
				  name="last_name"
				  autoComplete="last_name"
				/>
                
              </Grid>
            </Grid>
			
			<TextField
				  margin="normal"
				  
				  fullWidth
				  id="phone_number"
				  label="Phone Number (optional)"
				  name="phone_number"
				  autoComplete="phone_number"
				/>
			<br/><br/>
			<TextField
				  id="request_text"
				  label="Purpose for wanting to use the tool"
				  name="request_text"
				  multiline
				  rows={3}
				    required
				  sx={{
					width: '100%'
				  }}
				  defaultValue=""
				/>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send Request
            </Button>
            <Grid container>
              <Grid item xs>

              </Grid>
              <Grid item>
                
              </Grid>
            </Grid>
          </Box>
		  </>
	:<>Not available for this account type</>}
        </Box>
      </Container>
    </ThemeProvider>
	
  );
}

export default Welcome;