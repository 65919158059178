import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

var Config = require('../config.json');
import AuthService from '../service/auth'

import {useEffect,useState} from 'react';
import { useParams,useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';

import TopPublicNavigation from '../components/TopPublicNavigation.jsx'

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const Login=()=>{ 
	const navigate = useNavigate();
	
	const [ShowErrorMessage,setErrorStatus]=useState(false); 
	const [ErrorText,setErrorMessage]=useState(""); 
	
	const [ShowSuccessMessage,setSuccessStatus]=useState(false); 
	const [SuccessText,setSuccessMessage]=useState("");
	
	const [AccountType,setAccountType]=useState("Master");

	useEffect(() => {
		////logout
		AuthService.clearCurrentUser()
		
		//set account type from config
		setAccountType(Config.account_type);
		
		//check for verify email params
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let verifyemail = params.get('verifyemail');
		if(verifyemail!==null){
			if(verifyemail=='yes'){
				setSuccessMessage('Thank you for validating your email address ,you can sign in below');
				setSuccessStatus(true);
			}
		}
	}, []);
	
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		console.log({
		  email: data.get('email'),
		  password: data.get('password'),
		});
		checkLogin({'email':data.get('email'),'password':data.get('password'),'account_type':AccountType});
	};
	
	function checkLogin(credentials){
			setErrorStatus(false);
			setErrorMessage("");
			setSuccessStatus(false);
			setSuccessMessage("");
			AuthService.authenticate(credentials).then(function(res){
				console.log(res.data);
				AuthService.setCurrentUser(res.data);
				navigate("/home");
			}).catch(function(error){
				console.log(error);
				setErrorMessage(error.response.data.message);
				setErrorStatus(true);
			});
	}

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
		 <TopPublicNavigation Linked={true} LinkTo={'/login'} />
          <Typography component="h1" variant="h5">
		  {AccountType} Account Sign in
          </Typography>
		  	 
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
		  { ShowErrorMessage &&
				<Alert variant="filled" severity="error">{ErrorText}</Alert>
			} 
			{ ShowSuccessMessage &&
				<Alert variant="filled" severity="success">{SuccessText}</Alert>
			}
			
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
           
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgottenpassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Login;