import axios from "axios"
var Config = require('../config.json');
var session_name= Config.app_name+'_'+Config.account_type;

const AuthService = {
	setCurrentUser:function(user){
		console.log('user',user);
		localStorage.setItem(session_name+"_userLoggedIn", true);
		localStorage.setItem(session_name+"_loggedInUser", JSON.stringify(user));
		localStorage.setItem(session_name+"_bearerToken", user.token);
	},
	isUserLoggedIn:function(){
		return localStorage.getItem(session_name+"_userLoggedIn");
	},
	getCurrentUser:function(){
		return JSON.parse(localStorage.getItem(session_name+"_loggedInUser"));
	},
	getBearerToken:function(){
		return localStorage.getItem(session_name+"_bearerToken");
	},
	clearCurrentUser:function(){
		localStorage.removeItem(session_name+"_userLoggedIn");
		localStorage.removeItem(session_name+"_loggedInUser");
		localStorage.removeItem(session_name+"_bearerToken");
	},
	register:function(user){
	return axios.post(Config.api_url+'/'+user.account_type+'/register',user);
	},
	verifyEmail:function(user){
	return axios.post(Config.api_url+'/'+user.account_type+'/verifyEmail',user);
	},
	resendVerifyEmail:function(user){
	var bearerToken =AuthService.getBearerToken();
	const header = {
		headers: { Authorization: 'Bearer '+bearerToken }
	};
	console.log('user',user);
	return axios.post(Config.api_url+'/'+user.account_type+'/verifyEmail/resend',user,header);
	},
    authenticate: function(user) {
		return axios.post(Config.api_url+'/'+user.account_type+'/authenticate',user);
    },
    register: function(user) {
		return axios.post(Config.api_url+'/'+user.account_type+'/register',user);
    },
	resetPassword: function(user) {
		return axios.post(Config.api_url+'/'+user.account_type+'/resetpassword',user);
    }
};

export default AuthService;