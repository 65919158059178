import {useEffect,useState} from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import NotificationsIcon from '@mui/icons-material/Notifications';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

//services
import AuthService from '../service/auth'
import NotificationService from '../service/Notification'
import AccountProjectService from '../service/AccountProject'


const Notifications = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	
	const [numberNotifications, setNumberNotifications] = useState(0);
	const [notificationData, setNotificationData] = useState([]);
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
								
						
						}else{
							getProjectsByAccount();
							//createNotification({'recipient_account_type':'Master',
							//'type':'add new food request','project':'65cddea092e35532809d04e7',
							//'requested_by':'65b120769235432da8e51f1b'});
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
								
								
							}else if(account_type=='Researcher'){//Researcher
								
							}
						}
					}
				}	
		}
	}, []);
	
	function loadNotifications(filter){
		NotificationService.getByFilter(filter).then(function(res){
			//setClientData(res.data);
			console.log(res.data);
			setNumberNotifications(res.data.length);
			setNotificationData(res.data);
		}).catch(function(error){
				console.log(error);
		});
	}
	
	const [accountProjectData,setAccountProjectData]=useState([]);
	var projects=[];
	function getProjectsByAccount(){//by account
		AccountProjectService.getByFilter({'account':AuthService.getCurrentUser()._id}).then(function(res){
			console.log(res.data);
			for(var i=0;i<res.data.length;i++){
				projects.push(res.data[i].project._id);
			}
			setAccountProjectData(projects);
			var filter ={};
			if(projects.length>0){
				//filter={'recipient_account_type':AuthService.getCurrentUser().__t,'project':projects};
				filter={'recipient_account_type':AuthService.getCurrentUser().__t};
			}else{
				filter={'recipient_account_type':AuthService.getCurrentUser().__t};
			}
			console.log('filter',filter);
			console.log('projects',projects);
			loadNotifications(filter);

		}).catch(function(error){
				console.log(error);
		});
	};
	
	function createNotification(notification){
			NotificationService.create(notification).then(function(res){
				console.log(res.data);
				

			}).catch(function(error){
					console.log(error);
			});
		};
		
	function updateNotification(notification){
			NotificationService.update(notification).then(function(res){
				console.log(res.data);
				

			}).catch(function(error){
					console.log(error);
			});
		};
		
  const handleViewNotification = (event,notification) => {

    console.log("notification value is ", notification);
	notification.read=true;
	//mark as read
	updateNotification(notification);
	//direct to food item or account request
	if(notification.type=="add new food request"){
		//navigate("/food/request");
		navigate("/food/edit/"+notification.food._id);
	}
	if(notification.type=="account request"){
		//navigate("/accountrequest/"+notification.account_request._id);
		navigate("/");
	}
	//navigate("/login");
  };
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	<br/>
	<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/notifications"
        >
          Notifications
        </Link>
      </Breadcrumbs>
	<br/>
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}
	
	{(numberNotifications==0) ?
		<>
		<Typography variant="h5" component="h2">
		  No notifications
		</Typography>
		</>
	:<></>}
	
	
	{(numberNotifications>0) ?
		<>
		
		<Box  sx={{ width: '100%', bgcolor: 'background.paper' }}>
		<Divider />
		  <nav aria-label="">
			<List>
			{notificationData.map((row,index) => (
			  <ListItem key={row._id} disablePadding selected={!row.read}>
				<ListItemButton   onClick={(event) => {handleViewNotification(event,row); }}>
				  <ListItemIcon>
					<NotificationsIcon />
				  </ListItemIcon>
				  <ListItemText primary={row.message} />
				</ListItemButton>
			  </ListItem>
			  ))}
			</List>
		  </nav>
		<Divider />
		</Box>

		</>
	:<></>}
	
	

	</AppLogout>
	</>
  ) 
  ;
};

export default Notifications;