import {useEffect,useState} from 'react';
import { useNavigate ,useParams} from "react-router-dom";


import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'
import ClientService from '../service/Client'
import ProjectService from '../service/Project'

const formatJson=function(obj){
	return JSON.stringify(obj);
}

const Clients = () => {
	
	//let params = useParams();
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	const [clientData,setClientData]=useState([]);
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
								
						
						}else{
							
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								loadProjectById(project_id);
								loadClients({'project':project_id});
							}else{
								loadClients({});
							}
							//load
							if(account_type=='Admin'){//Admin
								//load all projects
							}else if(account_type=='Master'){//Master
								
								//loadClients({});
							}else if(account_type=='Researcher'){//Researcher
								//loadClients({});
							}
						}
					}
				}	
		}
	}, []);
	
	
	
		function loadProjectById(project_id){
			ProjectService.getById(project_id).then(function(res){
				setProjectData(res.data);
				console.log(res.data);
				setProjectFound(true);
			}).catch(function(error){
					console.log(error);
					setProjectFound(false);
			});
		}
		
	function loadClients(filter){
		ClientService.getByFilter(filter).then(function(res){
			console.log(res.data);
					setClientData(res.data);
		}).catch(function(error){
				console.log(error);
		});
	}
	
	
		/* sort fields */
	const [sortByField, setSortByField] = useState('date');
	const [sortType, setSortType] = useState('asc');//desc
	
	const handleSortProject = (e,sortByField) => {
	   console.log('default','sortByField',sortByField,'sortType',sortType);
	   let sorted;
	   
	   //field
	   if(sortByField=='date'){
		   console.log('sort by date.....');
		  		 if(sortType=='asc'){
		    sorted = [...clientData].sort((a, b) => {
			   var keyA = new Date(a.last_updated),
					keyB = new Date(b.last_updated);
				  if (keyA < keyB) return -1;
				  if (keyA> keyB) return 1;
				  return 0;
			});
		   
		   setSortType('desc');
		}else if(sortType=='desc'){
		      sorted = [...clientData].sort((a, b) => {
			    var keyA = new Date(a.last_updated),
					keyB = new Date(b.last_updated);
				  if (keyA > keyB) return -1;
				  if (keyA< keyB) return 1;
				  return 0;
				});
				   setSortType('asc');
		}
	   }else if(sortByField=='code'){
		  console.log('sort by code.....');
		 if(sortType=='asc'){
		    sorted = [...clientData].sort((a, b) => {
			   var keyA = a.code,
					keyB = b.code;
				  if (keyA < keyB) return -1;
				  if (keyA> keyB) return 1;
				  return 0;
			});
		   
		   setSortType('desc');
		}else if(sortType=='desc'){
		      sorted = [...clientData].sort((a, b) => {
			   var keyA = a.code,
					keyB = b.code;
				  if (keyA > keyB) return -1;
				  if (keyA< keyB) return 1;
				  return 0;
				});
				   setSortType('asc');
		}
	   }else{
		   console.log('dont know what to sort by.....');
	   }

    setClientData(sorted);
  };
  
  /*end sort fields */
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
	<br/>
	{(projectFound) ?
	<>
	<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/"> Home </Link>
		<Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>

		 <Link underline="hover" color="inherit" href={`/clients/project/${projectData._id}`} > Clients  </Link>
		{/*<Typography color="text.primary">test</Typography>*/}
      </Breadcrumbs>
	
	</>
	:<>
	<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/"> Home </Link>
		 <Link underline="hover" color="inherit" href="/clients" > Clients  </Link>
		{/*<Typography color="text.primary">test</Typography>*/}
      </Breadcrumbs>
	</>}
	
	  
	<br/>
	  <Divider />
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{((currentUser.__t=='Master') || (currentUser.__t=='Researcher')) ?
	<>
	
			<div style={{ width: '100%' }}>
		<Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 1,
          m: 1,
         // bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
	  
		
		 
	{(projectFound) ?
	<>
		<Button href={`/client/new/null`} color="primary" variant="contained"  endIcon={<AddIcon />}   size="small" >
		New Client
		</Button>
		<br/> <br/>
	</>
	:<> </>}
	
	  {(projectFound) ?
	<>
	{/*<Button href={`/client/questionaire`}  variant="outlined" endIcon={<DynamicFormIcon />} >
	{/*<Button href={`/client/questionaire`}  variant="outlined" endIcon={<DynamicFormIcon />} >
			Manage Questionaires
	  </Button>*/}
		
	</>
	:<> </>}
		  	

      </Box>
    </div>
	
	<br/><br/>
	{(clientData.length==0)?
	<>
	
	None found
	
	</>
	
	:<>
	
			 {/*start sort buttons */}
		<div style={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
         // bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <Button  size="small" onClick={(e) => handleSortProject(e,"date")}  variant="outlined" endIcon={<ImportExportIcon/>}>
		  Sort new/old
		</Button>&nbsp;
		<Button size="small" onClick={(e) => handleSortProject(e,"code")}  variant="outlined" endIcon={<ImportExportIcon/>}>
		  Sort a/z
		</Button>
		
      </Box>
    </div>
	
	 {/*end sort buttons */}
	
	
	
	
		<Grid container spacing={3}>
		{clientData.map((row,index) => (
         <Grid key={row._id} item item xs={12} sm={6} md={4}>
			<Card variant="outlined" sx={{ minWidth: 25 }}>
				<CardContent style={{ textAlign: 'center' }} >
					<Typography variant="h5" component="div">
					{row.code}
					</Typography>
					<Typography sx={{ mb: 1.5 }} color="text.secondary">
			  
					</Typography>
					<Typography variant="body2">
					
	
					{(projectFound) ?
					<>

		
					</>
					:<> 
					
					<b>Project</b> {row.project.name}<br/>
					{(row.created_by!==null) ?
					<>

					<b>Created By </b> {row.created_by.first_name} {row.created_by.last_name}
					</>
					:<> 
					<b>Created By </b> Unknown
					</>}
					
					
					</>}
						
						
					</Typography>
				</CardContent>
				<CardActions style={{justifyContent:'center'}}>
				<Button size="small"  href={`/client/view/${row._id}`}  >View</Button>
				{/*<Button href={`/client/edit/${row._id}`} color="success" variant="contained"  endIcon={<VisibilityIcon />}   size="small" >
					View
					</Button>
					{(projectFound) ?
				<>
					<Button href={`/client/view/${row._id}`} color="success" variant="contained"  endIcon={<VisibilityIcon />}   size="small" >
						View
					</Button>
					<Button href={`/client/edit/${row._id}`} color="primary" variant="contained"  endIcon={<EditIcon />}   size="small" >
						Edit
					</Button>
				</>
				:<>
					<Button href={`/client/view/${row._id}`} color="success" variant="contained"  endIcon={<VisibilityIcon />}   size="small" >
						View
					</Button>
					<Button href={`/client/edit/${row._id}`} color="primary" variant="contained"  endIcon={<EditIcon />}   size="small" >
						Edit
					</Button>

		</>}*/}
				 
				</CardActions>
			</Card>	
		</Grid>))}
	</Grid>
	
	
	
	</>
	}

	  

	
	
	</>
	:<></>}
	
<br/><br/><br/>
	</AppLogout>
	</>
  ) 
  ;
};

export default Clients;