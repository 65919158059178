import * as React from 'react';
import {Link } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';

import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';

//services
import AuthService from '../service/auth'
var Config = require('../config.json')
import NotificationService from '../service/Notification'
import AccountProjectService from '../service/AccountProject'


export default function TopPrivateNavigation(props) {
	
	const [currentUser,setCurrentUser]=React.useState({});
	
	const [numberNotifications, setNumberNotifications] = React.useState(0);
	const [notificationData, setNotificationData] = React.useState([]);

	const renderNotificationIcon = (
		<>
			{(numberNotifications>0) ?
			<Badge badgeContent={numberNotifications} color="error">
					<NotificationsIcon />
				  </Badge>
			:<></>}
			{(numberNotifications==0) ?
			<Badge color="error">
					<NotificationsIcon />
				  </Badge>
			:<></>}
		</>	
	);
	
	function loadNotifications(filter){
		NotificationService.getByFilter(filter).then(function(res){
			//setClientData(res.data);
			//console.log(res.data);
			setNumberNotifications(res.data.length);
			setNotificationData(res.data);
		}).catch(function(error){
				console.log(error);
		});
	}
	
	const [accountProjectData,setAccountProjectData]=React.useState([]);
	var projects=[];
	function getProjectsByAccount(){//by account
		AccountProjectService.getByFilter({'account':AuthService.getCurrentUser()._id}).then(function(res){
			//console.log(res.data);
			for(var i=0;i<res.data.length;i++){
				projects.push(res.data[i].project._id);
			}
			setAccountProjectData(projects);
			var filter ={};
			if(projects.length>0){
				filter={'read':false,'recipient_account_type':AuthService.getCurrentUser().__t,'project':projects};
			}else{
				filter={'read':false,'recipient_account_type':AuthService.getCurrentUser().__t};
			}
			loadNotifications(filter);

		}).catch(function(error){
				console.log(error);
		});
	};
	
	React.useEffect(() => {
		
		if(AuthService.isUserLoggedIn()){
			setCurrentUser(AuthService.getCurrentUser());
			var interval_length=6000;
			var account_type=AuthService.getCurrentUser().__t;
			if(account_type=='Admin'){//Admin
					var filter={'read':false,'recipient_account_type':AuthService.getCurrentUser().__t,'type':'account request'};	
					loadNotifications(filter);
					setInterval(() => {
						loadNotifications(filter);
					}, interval_length);//60 seconds
						
			}else if(account_type=='Master'){//Master
					getProjectsByAccount();		
					/*setInterval(() => {
						var filter ={};
						///console.log('projects',projects);
						if(projects.length>0){
							filter={'read':false,'recipient_account_type':AuthService.getCurrentUser().__t,'project':projects};
						}else{
							filter={'read':false,'recipient_account_type':AuthService.getCurrentUser().__t};
						}
						loadNotifications(filter);
			}, interval_length);*/
								
			}else if(account_type=='Researcher'){//Researcher
								
			}
			
		}

	}, []);
	
	
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (

  
   <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={isMenuOpen}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
	  {/* notifications 0 */}
		{(numberNotifications==0) ?
		
		
		<MenuItem onClick={handleMenuClose}>
          <Typography variant="inherit" >
            No new notifications
          </Typography>
        </MenuItem>
		
		:''}
		
	{/* notifications  */}
		{(numberNotifications>0) ?
		<>
		{notificationData.map((row,index) => (
		<MenuItem component={Link} to="/notifications">
          <Typography variant="inherit" >
		  {row.message}
          </Typography>
        </MenuItem>
		))}
		</>
		:''}
        
		{/* <MenuItem onClick={handleMenuClose}>
          <Typography variant="inherit" >
            notification 1
          </Typography>
        </MenuItem>
		<MenuItem onClick={handleMenuClose}>
          <Typography variant="inherit" >
            notification 2  
          </Typography>
        </MenuItem>
		<MenuItem onClick={handleMenuClose}>
          <Typography variant="inherit" >
            notification 3
          </Typography>
		</MenuItem>*/}
        <Divider />
        <MenuItem component={Link} to="/notifications">
          View all
        </MenuItem>
      </Menu>
  /* <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
</Menu>*/
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem component={Link} to="/notifications" >
        <IconButton
          size="large"
          aria-label=""
          color="inherit"
		
        >
		{renderNotificationIcon}
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
	   <MenuItem  component={Link} to="/account" >
        <IconButton
          size="large"
          aria-label=""
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
		
        >
          <AccountCircleIcon />
        </IconButton>
        <p>My Account</p>
      </MenuItem>
      <MenuItem  component={Link} to="/login" >
        <IconButton
          size="large"
          aria-label=""
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
		
        >
          <LogoutIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );
  
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
         
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              //fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
		  {Config.title} 
          </Typography>
		  <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              //fontFamily: 'monospace',
              fontWeight: 100,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
		  {currentUser.__t} account
          </Typography>
        
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
			  onClick={handleProfileMenuOpen}
            >
			{renderNotificationIcon}
              
            </IconButton>
			<IconButton
              size="large"
              edge="end"
              aria-label="current account"
              aria-controls={menuId}
              aria-haspopup="true"
              href={`/account`}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="logout"
              aria-controls={menuId}
              aria-haspopup="true"
              href={`/login`}
              color="inherit"
            >
              <LogoutIcon />
            </IconButton>
			
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}