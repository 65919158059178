import * as React from 'react';
import {useEffect,useState} from 'react';
import { useParams,useNavigate,Link } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import TopPublicNavigation from '../components/TopPublicNavigation.jsx'

import authService from '../service/auth'

const styles = {
 HeadingText: {
    color: '#080a0b',
    fontSize: '32px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    lineHeight: '40px',
    textAlign: 'center',
  }
};


const VerifyEmail = () => {
	
	const navigate = useNavigate();
	
	const [serviceData,setServiceData]=useState([]);
	
	const [ShowErrorMessage,setErrorStatus]=useState(false); 
	const [ErrorText,setErrorMessage]=useState(""); 
	
	const [ShowSuccessMessage,setSuccessStatus]=useState(false); 
	const [SuccessText,setSuccessMessage]=useState("");
	
	const [AccountType,setAccountType]=useState("");
	
	useEffect(()=> {
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let str = params.get('code');
		var decodedString = atob(str); // Base64 decode the String
		var urlParams = new URLSearchParams(decodedString);
		console.log(urlParams);
		var code = urlParams.get('code');
		var email = urlParams.get('email');
		var password = urlParams.get('password');
		var account_type = urlParams.get('account_type');
		var user={'account_type':account_type,
					'code':code,
					'email':email,
					'password':password
				};
		//set account type 
		setAccountType(account_type);
		
		console.log(user);
		authService.verifyEmail(user).then(function(res){
			console.log(res.data);
			setServiceData(res);
			navigate("/login?verifyemail=yes");
			}).catch(function(error){
				console.log(error);
				setErrorMessage('some thing went wrong :( try again later');
				setErrorStatus(true);
			});
	},[])
	
	
	
  return(
  <>
  
	<Typography variant="h5" gutterBottom>
         Verifying {AccountType} Account email....
      </Typography>
	{ ShowErrorMessage &&
		<Alert severity="error">{ErrorText}</Alert>
	} 
	{ ShowSuccessMessage &&
		<Alert severity="success">{SuccessText}</Alert>
	}
	
	</>
  ) 
  ;
};

export default VerifyEmail;