import axios from "axios"
var Config = require('../config.json')
import AuthService from '../service/auth'
var entity='Notification';//notification

const Service = {
    create: function(project) {
	var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.post(Config.api_url+'/'+entity,project,header);
    },
    getAll: function() {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity,header);
    },
    getById: function(id) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity+'/'+id,header);
    },
    getByFilter: function(filter) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		var qs = serialize(filter);
		//console.log('filter',filter);
		//console.log('qs',qs);
		return axios.get(Config.api_url+'/'+entity+'/filter/params?'+qs,header);
    },
    update: function(request) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.put(Config.api_url+'/'+entity+'/'+request._id,request,header);
    },
    deleteById: function(id) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.delete(Config.api_url+'/'+entity+'/'+id,header);
    }
};

function serialize(obj) {
	var str = [];
	for (var p in obj)
	if (obj.hasOwnProperty(p)) {
		if(Array.isArray(obj[p])){
			for(var i=0;i<obj[p].length;i++){
				str.push(encodeURIComponent(p) + "[]=" + encodeURIComponent(obj[p][i]));
			}
		}else{
			str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
		}
		
	}
	return str.join("&");
}
		
export default Service;