import axios from "axios"
var Config = require('../config.json')
import AuthService from '../service/auth'
var entity='Reports';

const ReportsService = {
    exists: function(filename) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		var url =Config.api_url+'/'+entity+'/exists/'+filename
		console.log('url',url);
		return axios.get(url,header);
    },
    generateReport: function(opt) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		var qs = serialize(opt);
		var url =Config.api_url+'/'+entity+'/generateReport?'+qs;
		return axios.get(url,header);
    },
	 getUrl: function(filename) {
		//http://localhost:8092/public/reports/download/g2xTRtwQNZhKreY9xwChXvEpwxUFMBaMbu.csv
		var url =Config.api_url+'/public/'+entity+'/download/'+filename
		return url;
    }
};

function serialize(obj) {
	var str = [];
		for (var p in obj)
			if (obj.hasOwnProperty(p)) {
				str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
			}
			return str.join("&");
}
		
export default ReportsService;