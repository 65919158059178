import * as React from 'react';
import {useEffect,useState} from 'react';
import { useNavigate } from "react-router-dom";

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'

import AuthService from '../service/auth'
import AdminAccountService from '../service/AdminAccount'
import AccountRequestService from '../service/AccountRequest';

const Test = () => {
		
	const navigate = useNavigate();
	
	const [currentUser,setCurrentUser]=useState({});
	
	const [adminAccountData,setAdminAccountData]=useState([]);
	const [accountRequestsData,setAccountRequestsData]=useState([]);
	
	useEffect(()=> {
		
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
		  		AdminAccountService.getAll().then(function(res){
					setAdminAccountData(res.data);
					console.log(res.data);
				});
				
				AccountRequestService.getByFilter({'type':'Master'}).then(function(res){
					setAccountRequestsData(res.data);
					console.log(res.data);
				});
		}
		
	},[])
	
  return( 
	<>
	<TopPrivateNavigation/>
	<h1>Admin Accounts ({adminAccountData.length})</h1>

	<table style={{width: "80%"}}>
		<thead>
		  <tr>
			<th>FirstName</th>
			<th>LastName</th>
			<th>Email</th>
		  </tr>
	  </thead>
	  	<tbody>
	{adminAccountData.map((data)=>(
	
	  <tr key={data.id}>
		<td>{data.first_name}</td>
		<td>{data.last_name}</td>
		<td>{data.email}</td>
	  </tr>

	))}
	</tbody>
	</table>
	
	<h1>Master Account requests ({accountRequestsData.length})</h1>

	<table style={{width: "80%"}}>
		<thead>
		  <tr>
		  <th>Email</th>
			<th>FirstName</th>
			<th>LastName</th>
			<th>Phone Number</th>
			<th>Organisation/Affiliation</th>
			<th>Request Message</th>
		  </tr>
	  </thead>
	  	<tbody>
	{accountRequestsData.map((data)=>(
	
	  <tr key={data.id}>
	  	<td>{data.email}</td>
		<td>{data.first_name}</td>
		<td>{data.last_name}</td>
		<td>{data.phone_number}</td>
		<td>{data.organisation}</td>
		<td>{data.request_text}</td>
	  </tr>

	))}
	</tbody>
	</table>
</>
 ); 

};

export default Test;
