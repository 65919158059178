import {useEffect,useState} from 'react';
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';

//services
import AuthService from '../service/auth'
import ProjectService from '../service/Project'
import RecipeService from '../service/Recipe'

const formatJson=function(obj){
	return JSON.stringify(obj);
}

const Recipes = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	
	//let params = useParams();
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				//console.log('account_type',account_type);
				
				//console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					//console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					//console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						//console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						//console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
							//not implemented	
						
						}else{
							//load project if set 
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								loadProjectById(project_id);
							}
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
								
								loadRecipes();
								
							}else if(account_type=='Researcher'){//Researcher
								
							}
						}
					}
				}	
		}
	}, []);
	
	function loadProjectById(project_id){
		ProjectService.getById(project_id).then(function(res){
		setProjectData(res.data);
		console.log(res.data);
		setProjectFound(true);
		}).catch(function(error){
			console.log(error);
			setProjectFound(false);
		});
	}

	const [recipeData,setRecipeData]=useState([]);
	function loadRecipes(){
		var recipeFilter ={'global':true}
		if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
			recipeFilter ={'global':false,'project':project_id}
		}
		recipeFilter.active=true;		
		console.log(recipeFilter);
		RecipeService.getByFilter(recipeFilter).then(function(res){
		setRecipeData(res.data);
		console.log(res.data);
		}).catch(function(error){
			console.log(error);
		});
	}
	
	/*switch view*/
	const [dataView, setDataView] = useState('table');
	const handleSwitchView = (e,view) => {
			setDataView(view)
	
	};
	
			/* sort fields */
	const [sortByField, setSortByField] = useState('date');
	const [sortType, setSortType] = useState('asc');//desc
	
	const handleSortRecipes = (e,sortByField) => {
	   console.log('default','sortByField',sortByField,'sortType',sortType);
	   let sorted;
	   
	   //field
	   if(sortByField=='date'){
		   console.log('sort by date.....');
		  		 if(sortType=='asc'){
		    sorted = [...recipeData].sort((a, b) => {
			   var keyA = new Date(a.last_updated),
					keyB = new Date(b.last_updated);
				  if (keyA < keyB) return -1;
				  if (keyA> keyB) return 1;
				  return 0;
			});
		   
		   setSortType('desc');
		}else if(sortType=='desc'){
		      sorted = [...recipeData].sort((a, b) => {
			    var keyA = new Date(a.last_updated),
					keyB = new Date(b.last_updated);
				  if (keyA > keyB) return -1;
				  if (keyA< keyB) return 1;
				  return 0;
				});
				   setSortType('asc');
		}
	   }else if(sortByField=='name'){
		  console.log('sort by name.....');
		 if(sortType=='asc'){
		    sorted = [...recipeData].sort((a, b) => {
			   var keyA = a.name,
					keyB = b.name;
				  if (keyA < keyB) return -1;
				  if (keyA> keyB) return 1;
				  return 0;
			});
		   
		   setSortType('desc');
		}else if(sortType=='desc'){
		      sorted = [...recipeData].sort((a, b) => {
			   var keyA = a.name,
					keyB = b.name;
				  if (keyA > keyB) return -1;
				  if (keyA< keyB) return 1;
				  return 0;
				});
				   setSortType('asc');
		}
	   }else{
		   console.log('dont know what to sort by.....');
	   }

    setRecipeData(sorted);
  };
  
  /*end sort fields */
  
  
  /*delete recipe modal*/
  
  const [openDeleteRecipeModal, setOpenDeleteRecipeModal] = useState(false);
  const [tempRecipe, setTempRecipe] = useState({});

  const handleDeleteRecipeModal = (e,recipe) => {
	  setTempRecipe(recipe);
	  console.log('recipe',recipe);
		setOpenDeleteRecipeModal(true);
	  };

	  const handleClose = () => {
		setOpenDeleteRecipeModal(false);
	  };
	  
	  const handleDeleteRecipe=()=>{
		//setOpenDeleteRecipeModal(false);
		console.log('tempRecipe',tempRecipe);
		tempRecipe.active=false;
		RecipeService.update(tempRecipe).then(function(res){
		//setRecipeData(res.data);
		console.log(res.data);
		setOpenDeleteRecipeModal(false);
		loadRecipes();
		}).catch(function(error){
			console.log(error);
		});
	  };

	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
		{/* error no project found */}
	{(!projectFound) ?
		<>
		
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
		 <Typography color="text.primary">Recipes</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
		
		
		</>
	:<></>}
	{/* if project is found */}
	{(projectFound) ?
	<>
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>
		 <Typography color="text.primary">Recipes</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
	
	</>
	:<></>}
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>

	<Button href={`/recipe/New`}  variant="contained" endIcon={<AddIcon />}  >
		New Recipe 
	</Button>
	<br/><br/>
	{/* Master */}
	{(recipeData.length==0) ?
	<>No recipes found</>
	:<>
	
		 {/*start sort buttons */}
		<div style={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
         // bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
		{dataView=='grid'?
		<>
			<Button  size="small" onClick={(e) => handleSwitchView(e,"table")}  variant="outlined" endIcon={<TableRowsOutlinedIcon/>}>View</Button>&nbsp;
		</>:<>
			<Button  size="small" onClick={(e) => handleSwitchView(e,"grid")} variant="outlined" endIcon={<GridViewOutlinedIcon/>}>View</Button>&nbsp;
		</>}
	 
        <Button  size="small" onClick={(e) => handleSortRecipes(e,"date")}  variant="outlined" endIcon={<ImportExportIcon/>}>
		  Sort new/old
		</Button>&nbsp;
		<Button size="small" onClick={(e) => handleSortRecipes(e,"name")}  variant="outlined" endIcon={<ImportExportIcon/>}>
		  Sort a/z
		</Button>
		
      </Box>
    </div>
	
	 {/*end sort buttons */}
	 
	 <Dialog
        open={openDeleteRecipeModal}
        onClose={handleClose}
        //aria-labelledby="alert-dialog-title"
		//aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this recipe?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
		  
          </DialogContentText>
        </DialogContent>
        <DialogActions>
			<Button onClick={handleClose}>No</Button>
			<Button onClick={handleDeleteRecipe} > Yes</Button>
        </DialogActions>
      </Dialog>
	  {(dataView=='table')?
	  <>
	  <TableContainer component={Paper}>
		<Table sx={{ minWidth: 650 }} aria-label="simple table">
			<TableHead>
						  <TableRow>
							<TableCell>Code</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>Description</TableCell>
							<TableCell></TableCell>
						  </TableRow>
						</TableHead>
						<TableBody>
						  {recipeData.map((row,index) => (
							<TableRow
							  key={index}
							  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
							 <TableCell component="th" scope="row">
								{row.code}
							  </TableCell>
							  <TableCell component="th" scope="row">
								{row.name}
							  </TableCell>
							 <TableCell component="th" scope="row">
								{row.description}
							  </TableCell>
							   <TableCell component="th" scope="row" align="right">
								<Tooltip title="View">
								  <IconButton href={`/recipe/view/${row._id}`} aria-label="view">
									<VisibilityIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Edit">
								   <IconButton href={`/recipe/edit/${row._id}`} aria-label="edit">
									<EditIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Clone">
								  <IconButton href={`/recipe/clone/${row._id}`} aria-label="copy">
									<ContentCopyIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Delete">
								   <IconButton onClick={(e) => handleDeleteRecipeModal(e,row)} aria-label="delete">
									<DeleteIcon />
								  </IconButton>
								</Tooltip>
								
							  </TableCell>
							  
							</TableRow>
						  ))}
						</TableBody>
					  </Table>
					</TableContainer>
	  
	  </>
	  :<>
	  
	  
	   <Grid container spacing={3} >
			  {recipeData.map((row,index) => (
			 <Grid key={row._id} item item xs={12} sm={6} md={4} >
				 <Card variant="outlined" sx={{ minWidth: 25 }} >
				
				<CardHeader style={{ textAlign: 'center' }} 
       
				 
					title={row.code}
						
				  />
					<CardContent style={{ textAlign: 'center' }} >
						<Typography variant="h5" component="div">
							{row.name}
						</Typography>
						<Typography sx={{ mb: 1.5 }} color="text.secondary">
							{row.description}
						</Typography>
						<Typography variant="body2">
						{/*<b>Last updated:</b>{formatDate(row.last_updated)}
			<br />*/}
						</Typography>
					</CardContent>
					<CardActions style={{justifyContent:'center'}}>
					<Tooltip title="View">
								  <IconButton href={`/recipe/view/${row._id}`} aria-label="view">
									<VisibilityIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Edit">
								   <IconButton href={`/recipe/edit/${row._id}`} aria-label="edit">
									<EditIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Clone">
								  <IconButton href={`/recipe/clone/${row._id}`} aria-label="copy">
									<ContentCopyIcon />
								  </IconButton>
								</Tooltip>
								<Tooltip title="Delete">
								   <IconButton onClick={(e) => handleDeleteRecipeModal(e,row)} aria-label="delete">
									<DeleteIcon />
								  </IconButton>
								</Tooltip>
				  </CardActions>
			</Card>	
			</Grid>
			
			))}
		</Grid>
	  
	  </>}
	
	</>}
		
	
	
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Recipes;