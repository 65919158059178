import axios from "axios"
var Config = require('../config.json')
import AuthService from '../service/auth'
var entity='Exports';

const ExportsService = {
    test: function(filter) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		var qs = serialize(filter);
		console.log('filter',filter);
		console.log('qs',qs);
		var url =Config.api_url+'/'+entity+'/testcsv?'+qs
		console.log('url',url);
		return axios.get(url,header);
    },
	
    build: function(table,filter) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		var qs = serialize(filter);
		console.log('filter',filter);
		console.log('qs',qs);
		var url =Config.api_url+'/'+entity+'/build/table/'+table+'/?'+qs;
		console.log('url',url);
		return axios.get(url,header);
    },
    exists: function(filename) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		var url =Config.api_url+'/'+entity+'/exists/'+filename
		console.log('url',url);
		return axios.get(url,header);
    },
    generateReport: function(opt) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		var qs = serialize(opt);
		console.log('opt',opt);
		console.log('qs',qs);
		var url =Config.api_url+'/'+entity+'/generateReport?'+qs;
		console.log('url',url);
		return axios.get(url,header);
    },
	 getUrl: function(filename) {
		//http://localhost:8092/public/exports/download/g2xTRtwQNZhKreY9xwChXvEpwxUFMBaMbu.csv
		var url =Config.api_url+'/public/'+entity+'/download/'+filename
		return url;
    }
};

function serialize(obj) {
	var str = [];
	for (var p in obj)
	if (obj.hasOwnProperty(p)) {
		if(Array.isArray(obj[p])){
			for(var i=0;i<obj[p].length;i++){
				str.push(encodeURIComponent(p) + "[]=" + encodeURIComponent(obj[p][i]));
			}
		}else{
			str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
		}
		
	}
	return str.join("&");
}
		
export default ExportsService;