import {useEffect,useState} from 'react';
import { useNavigate } from "react-router-dom";


import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'
import AccountProjectService from '../service/AccountProject'
import ProjectService from '../service/Project'

const formatJson=function(obj){
	return JSON.stringify(obj);
}

const formatDate=function(d){
	var d = new Date(d);
	Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}
    var dformat = [
               d.getDate().padLeft(),
			   (d.getMonth()+1).padLeft(),
               d.getFullYear()].join('/') +' ' +
              [d.getHours().padLeft(),
               d.getMinutes().padLeft()].join(':');
	return dformat;
}

const Projects = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	
	const [accountProjectData,setAccountProjectData]=useState([]);
	function loadProjects(){//
		ProjectService.getAll().then(function(res){
			var projects=res.data;
			console.log(res.data);
			var _projects=[];
			for(var i=0;i<projects.length;i++){
				if(!projects[i].is_private){//public
					_projects.push(projects[i]);
				}else{
					//check it created by account
					if(projects[i].created_by._id==AuthService.getCurrentUser()._id){
						_projects.push(projects[i]);
					}
				}
			}
			setAccountProjectData(projects);

		}).catch(function(error){
				console.log(error);
		});
	};
	
	function loadProjectsByAccount(){//by account
		AccountProjectService.getByFilter({'account':AuthService.getCurrentUser()._id}).then(function(res){
			console.log(res.data);
			var _projects=[];
			var _accountProjects=res.data;
			for(var i =0;i<_accountProjects.length;i++){
				_projects.push(_accountProjects[i].project);
			}
			console.log('_projects',_projects);
			setAccountProjectData(_projects);

		}).catch(function(error){
				console.log(error);
		});
	};
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
								
						
						}else{
							//load
							if(account_type=='Admin'){//Admin
								//load all projects
							}else if(account_type=='Master'){//Master
								loadProjects();
								//loadProjectsByAccount();
							}else if(account_type=='Researcher'){//Researcher
								loadProjectsByAccount();
							}
						}
					}
				}	
		}
	}, []);
	
	//handleChangeProject
	const handleChangeProject = (e,project) => {
			console.log(project);
			ProjectService.setCurrentProject(project._id);
			navigate("/project/"+project._id);
	};
	
	
	/* sort fields */
	const [sortByField, setSortByField] = useState('date');
	const [sortType, setSortType] = useState('asc');//desc
	
	const handleSortProject = (e,sortByField) => {
	   console.log('default','sortByField',sortByField,'sortType',sortType);
	   let sorted;
	   
	   //field
	   if(sortByField=='date'){
		   console.log('sort by date.....');
		  		 if(sortType=='asc'){
		    sorted = [...accountProjectData].sort((a, b) => {
			   var keyA = new Date(a.last_updated),
					keyB = new Date(b.last_updated);
				  if (keyA < keyB) return -1;
				  if (keyA> keyB) return 1;
				  return 0;
			});
		   
		   setSortType('desc');
		}else if(sortType=='desc'){
		      sorted = [...accountProjectData].sort((a, b) => {
			    var keyA = new Date(a.last_updated),
					keyB = new Date(b.last_updated);
				  if (keyA > keyB) return -1;
				  if (keyA< keyB) return 1;
				  return 0;
				});
				   setSortType('asc');
		}
	   }else if(sortByField=='name'){
		  console.log('sort by name.....');
		 if(sortType=='asc'){
		    sorted = [...accountProjectData].sort((a, b) => {
			   var keyA = a.name,
					keyB = b.name;
				  if (keyA < keyB) return -1;
				  if (keyA> keyB) return 1;
				  return 0;
			});
		   
		   setSortType('desc');
		}else if(sortType=='desc'){
		      sorted = [...accountProjectData].sort((a, b) => {
			   var keyA = a.name,
					keyB = b.name;
				  if (keyA > keyB) return -1;
				  if (keyA< keyB) return 1;
				  return 0;
				});
				   setSortType('asc');
		}
	   }else{
		   console.log('dont know what to sort by.....');
	   }

    setAccountProjectData(sorted);
  };
  
  /*end sort fields */
	
	//new project 
	const [openNewProjectModal, setNewProjectModalOpen] = useState(false);
		  
	const handleNewProjectModalOpen = () => {
		setNewProjectModalOpen(true);
	};

	const handleNewProjectModalClose = () => {
		setNewProjectModalOpen(false);
	};
	
	const [ShowErrorMessage,setErrorStatus]=useState(false); 
	const [ErrorText,setErrorMessage]=useState(""); 
	
	const [ShowSuccessMessage,setSuccessStatus]=useState(false); 
	const [SuccessText,setSuccessMessage]=useState("");
	
	const handleSubmitNewProject=(event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		var project={'name':data.get('name'),
					'description':data.get('description'),
					'is_private':checkSwitchValue(data.get('private')),
					'created_by':AuthService.getCurrentUser()._id
					};
		console.log('project',project);
		if(project.name){	
			createProject(project);
			setNewProjectModalOpen(false);		
		}else{
			setErrorStatus(true);
			setErrorMessage("Please ensure all required fields are completed correctly!!");
		}
	};
	
	function checkSwitchValue(val){
		if(val=='on'){
			return true;
		}
		return false;
	}
	
	function createProject(project){//by account
		setErrorStatus(false);
		setErrorMessage("");
		setSuccessStatus(false);
		setSuccessMessage("");
		ProjectService.create(project).then(function(res){
			console.log(res.data);
			setNewProjectModalOpen(false);	
			if((currentUser.__t=='Master')){
				loadProjects();
			}else if((currentUser.__t=='Researcher')){
				loadProjectsByAccount();
			}
					
		}).catch(function(error){
				console.log(error);
		});
	};
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
	<br/>
	<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/"> Home </Link>
		 <Link underline="hover" color="inherit" href="/projects" > Projects  </Link>
		{/*<Typography color="text.primary">test</Typography>*/}
      </Breadcrumbs>
	<br/>
	  <Divider />
	<br/>
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
		<Button  variant="contained" endIcon={<AddIcon />}  onClick={handleNewProjectModalOpen}>
			New Project 
		</Button>
	  {/* manage project dialog add/edit*/}
      <Dialog
        open={openNewProjectModal}
      >
	  <Box component="form" onSubmit={handleSubmitNewProject} noValidate sx={{ mt: 1 }}>
        <DialogTitle>New Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
      
          </DialogContentText>
		  { ShowErrorMessage &&
				<Alert variant="filled" severity="error">{ErrorText}</Alert>
			} 
			{ ShowSuccessMessage &&
				<Alert variant="filled" severity="success">{SuccessText}</Alert>
			}
		  
          <TextField
				  margin="normal"
				  required
				  fullWidth
				  id="name"
				  label="Name"
				  name="name"
				  autoComplete="name"
				/>
		  <br/>
			<TextField
				  id="description"
				  label="Description (optional)"
				  name="description"
				  multiline
				  rows={3}
				
				  sx={{
					width: '100%'
				  }}
				  defaultValue=""
				/>
				<br/><br/>
				<FormGroup>
				  <FormControlLabel name="private" control={<Switch  />} label="Private" />
				</FormGroup>
				
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNewProjectModalClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
		</Box>
      </Dialog>
	
	{/*  project */}
	  <br/> 
	  	{(accountProjectData.length==0) ?
		<>
		<br/>
		None found
		</>
		:<>
		
	<div style={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
         // bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <Button  size="small" onClick={(e) => handleSortProject(e,"date")}  variant="outlined" endIcon={<ImportExportIcon/>}>
		  Sort new/old
		</Button>&nbsp;
		<Button size="small" onClick={(e) => handleSortProject(e,"name")}  variant="outlined" endIcon={<ImportExportIcon/>}>
		  Sort a/z
		</Button>
		
      </Box>

     
    </div>
		
		 <Grid container spacing={3} >
			{accountProjectData.map((row,index) => (
			 <Grid key={row._id} item item xs={12} sm={6} md={4} >
				 <Card variant="outlined" sx={{ minWidth: 25 }} >
				
				<CardHeader style={{ textAlign: 'center' }} 
       
				   action={<IconButton disabled aria-label="settings">
								{(row.is_private) ?
								<>
								<LockIcon />
								</>
								:<>
								 <LockOpenIcon />
								 </>}
								 </IconButton>
							}
					title={row.name}
						
				  />
					<CardContent>
						<Typography variant="h5" component="div">
							
						</Typography>
						<Typography sx={{ mb: 1.5 }} color="text.secondary">
				  
						</Typography>
						<Typography variant="body2">
						{/*<b>Last updated:</b>{formatDate(row.last_updated)}
			<br />*/}
						</Typography>
					</CardContent>
					<CardActions style={{justifyContent:'center'}}>
					<Button size="small"  onClick={(e) => handleChangeProject(e,row)}  >View</Button>
				  </CardActions>
			</Card>	
			</Grid>
			
			))}
		</Grid>
		
		</>}

	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
		 {/*  project */}
	  <br/> <br/>
	  	{(accountProjectData.length==0) ?
		<>
		None found
		</>
		:<>
		
		
		 {/*start sort buttons */}
		<div style={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
         // bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <Button  size="small" onClick={(e) => handleSortProject(e,"date")}  variant="outlined" endIcon={<ImportExportIcon/>}>
		  Sort new/old
		</Button>&nbsp;
		<Button size="small" onClick={(e) => handleSortProject(e,"name")}  variant="outlined" endIcon={<ImportExportIcon/>}>
		  Sort a/z
		</Button>
		
      </Box>
    </div>
	
	 {/*end sort buttons */}
	 
		
		 <Grid container spacing={3} >
			{accountProjectData.map((row,index) => (
			 <Grid key={row._id} item item xs={12} sm={6} md={4}>
				 <Card variant="outlined" sx={{ minWidth: 25 }}>
				
				<CardHeader style={{ textAlign: 'center' }} 
       
				   action={<IconButton disabled aria-label="settings">
								{(row.is_private) ?
								<>
								<LockIcon />
								</>
								:<>
								 <LockOpenIcon />
								 </>}
								 </IconButton>
							}
					title={row.name}
						
				  />
					<CardContent>
						<Typography variant="h5" component="div">
							
						</Typography>
						<Typography sx={{ mb: 1.5 }} color="text.secondary">
				  
						</Typography>
						<Typography variant="body2">
							{row.description}<br/><b>Last updated:</b>{formatDate(row.last_updated)}
							<br />
						</Typography>
					</CardContent>
					<CardActions style={{justifyContent:'center'}}>
					<Button size="small"  onClick={(e) => handleChangeProject(e,row)}  >View</Button>
				  </CardActions>
			</Card>	
			</Grid>
			
			))}
		</Grid>
		
		</>}
	
	
	
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Projects;