import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import AuthService from '../service/auth'
import AccountRequestService from '../service/AccountRequest';
import AccountInviteService from '../service/AccountInvite';
import AccountProjectService from '../service/AccountProject';

import {useEffect,useState} from 'react';
import { useParams,useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';

import TopPublicNavigation from '../components/TopPublicNavigation.jsx'

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


const Register=()=>{ 
	const navigate = useNavigate();
	
	const [InvalidRequest,setInvalidRequestStatus]=useState(false); 

	const [ShowErrorMessage,setErrorStatus]=useState(false); 
	const [ErrorText,setErrorMessage]=useState(""); 
	
	const [ShowSuccessMessage,setSuccessStatus]=useState(false); 
	const [SuccessText,setSuccessMessage]=useState("");

	const [serviceRequestData,setServiceRequestData]=useState([]);	
	
	const [AccountType,setAccountType]=useState("");
	const [AccountRequest,setAccountRequest]=useState({});
	
		//check for params
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let str = params.get('code');
		var decodedString = atob(str); // Base64 decode the String
		var urlParams = new URLSearchParams(decodedString);
		console.log(urlParams);
		var project_id = urlParams.get('project_id');
		var id = urlParams.get('id');
		var account_type = urlParams.get('account_type');
		var email = urlParams.get('email');
		var request_code = urlParams.get('request_code');
		var request_type = urlParams.get('request_type');
	
	useEffect(() => {
		////logout
		//localStorage.clear();
		AuthService.clearCurrentUser()
		

		var user={
			'_id':id,
			//'project_id':project_id,
			'account_type':account_type,
			'request_code':request_code,
			'email':email
		};
		
		//set account type 
		setAccountType(account_type);
		setAccountRequest(user);
		console.log('request_type:',request_type);
		console.log('user reg:',user);
		
		if(request_type=="Requested"){
			AccountRequestService.getRequest(user).then(function(res){
				console.log(res.data);
				if(res.data.length==1){
					setServiceRequestData(res.data[0]);
				}else{
					setErrorMessage("Invalid request!!");
					setErrorStatus(true);
					setInvalidRequestStatus(true);
				}
			}).catch(function(error){
				console.log(error);
				setErrorMessage(error.response.data.message);
				setErrorStatus(true);
				setInvalidRequestStatus(true);
			});
		}
		
		if(request_type=="Invited"){
			user.project=project_id;
			console.log('user',user);
			AccountInviteService.getInvite(user).then(function(res){
				console.log(res.data);
				if(res.data.length==1){
					setServiceRequestData(res.data[0]);
				}else{
					setErrorMessage("Invalid request!!");
					setErrorStatus(true);
					setInvalidRequestStatus(true);
				}
			}).catch(function(error){
				console.log(error);
				setErrorMessage(error.response.data.message);
				setErrorStatus(true);
				setInvalidRequestStatus(true);
			});
			
			
		}
				
		
		
	}, []);
	
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		setErrorStatus(false);
		setErrorMessage("");
		setSuccessStatus(false);
		setSuccessMessage("");
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		var password= data.get('password');
		var repeat_password= data.get('repeat_password');
		console.log({
		  password: data.get('password'),
		  repeat_password: data.get('repeat_password'),
		});
		//Min eight chars, at least 1 uppercase letter, one lowercase letter, one number and one special character
		//const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
			const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
		if(password!==repeat_password){
			setErrorMessage("Passwords must match");
			setErrorStatus(true);
		}else{
			if(!passwordRegexp.test(password)){
				setErrorMessage("Passwords must have Min 8 chars, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character");
				setErrorStatus(true);
			}else{//password passed!!
				console.log('serviceRequestData',serviceRequestData);
				var user =serviceRequestData;
				user.account_type=serviceRequestData.account_type;
				user.account_request=serviceRequestData._id;
				user.password=password;
				if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
					user.project_id=project_id;			
				}
						console.log('this is request_type:',request_type);
				user.request_type=request_type;
				user.request_invite_id=serviceRequestData._id;
				
				register(user);
			}
		}
	};
	
	const [serviceData,setServiceData]=useState([]);
	function register(user){
			AuthService.register(user).then(function(res){
				console.log(res.data);
				setServiceData(res);
				setSuccessStatus(true);
				setSuccessMessage("You are now registered, we have sent you an email to verify your email, Thank you ");
				console.log('AccountRequest',AccountRequest);
				//registerUserOnProject(res.data._id,project_id)
			}).catch(function(error){
				console.log(error);
				setErrorMessage(error.response.data.message);
				setErrorStatus(true);
			});

	}
	
	function registerUserOnProject(account_id,project_id){
			AccountProjectService.register({'account':account_id,'project':project_id}).then(function(res){
				console.log(res.data);
				//setServiceData(res);
				//setSuccessStatus(true);
				///setSuccessMessage("You are now registered, we have sent you an email to verify your email, Thank you ");

			}).catch(function(error){
				console.log(error);
				//setErrorMessage(error.response.data.message);
				//setErrorStatus(true);
			});

	}

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
		{/*   <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
</Avatar>*/}
		  <TopPublicNavigation Linked={false} />
			  {(request_type!=='Requested' && request_type!='Invited')?
			  
			  <>
			  
			   <Typography component="h1" variant="h6">
				Register Not available
			  </Typography>
			  
			  </>:<>
			  
			   <Typography component="h1" variant="h6">
            Complete {AccountType} Account Registration for email {serviceRequestData.email}
          </Typography>
		  
		   <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
			{ShowErrorMessage &&
				<Alert variant="filled" severity="error">{ErrorText}</Alert>
			} 
			{ ShowSuccessMessage &&
				<Alert variant="filled" severity="success">{SuccessText}</Alert>
			}
			{!InvalidRequest && 
			<>
			{/*    <TextField
			 disabled
              margin="normal"
              required
              fullWidth
              id="email"
              label=""
              name="email"
              autoComplete="email"
				value={serviceRequestData.email}
              
            />*/}
			
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
			  autoFocus
            />
			
            <TextField
              margin="normal"
              required
              fullWidth
              name="repeat_password"
              label="Repeat Password"
              type="password"
              id="repeat_password"
              autoComplete="current-password"
            />
           
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Complete Registration
            </Button>
			</>
			}
            <Grid container>
              <Grid item xs>

              </Grid>
              <Grid item>
                
              </Grid>
            </Grid>
          </Box>
			  
			  
			  </>}
         
		  	 
         
        </Box>
		
		
      </Container>
    </ThemeProvider>
  );
}

export default Register;