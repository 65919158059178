import {Outlet} from "react-router-dom";
import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const Layout = () => {
  return (
  <>
  <div>
   <Container  >
      <Box >
	  <>
      <Outlet />
    </>
      </Box>
    </Container>
	</div>
	</>
  )
};

export default Layout;

