import {useEffect,useState} from 'react';
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Box  from '@mui/material/Box';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'
import ProjectService from '../service/Project'
import AccountProjectService from '../service/AccountProject'
import AccountInvite from '../service/AccountInvite'
import ResearcherAccountService from '../service/ResearcherAccount'

import PropTypes from 'prop-types';
//import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
//import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
//import TableCell from '@mui/material/TableCell';
//import TableContainer from '@mui/material/TableContainer';
//import TableHead from '@mui/material/TableHead';
//import TableRow from '@mui/material/TableRow';
//import Typography from '@mui/material/Typography';
//import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="left">{row.protein}</TableCell>
		<TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
		  Click here to expand projects
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
/*
Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};*/

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];

function validateEmail(email){
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const formatJson=function(json){
	
	return JSON.stringify(json)
}

const formatDate=function(d){
	var d = new Date(d);
	Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}
    var dformat = [
               d.getDate().padLeft(),
			   (d.getMonth()+1).padLeft(),
               d.getFullYear()].join('/') +' ' +
              [d.getHours().padLeft(),
               d.getMinutes().padLeft()].join(':');
	return dformat;
}

const Researchers = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	
	//let params = useParams();
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
	
	const [researcherAcountsData,setResearchersData]=useState([]);
	const [researcherProjectsData,setResearchersProjectsData]=useState({});
	function loadResearchersByProject(project_id){//by account
		AccountProjectService.getByFilter({'project':project_id,'account_type':'Researcher'}).then(function(res){
			var _accounts=res.data;
			var _accs=[];
			for(var i=0;i<_accounts.length;i++){
				console.log(_accounts[i]);
				_accs.push(_accounts[i].account);
			}
			setResearchersData(_accs);

		}).catch(function(error){
				console.log(error);
		});
	};
	
	function loadAllResearchers(){
		ResearcherAccountService.getAll().then(function(res){
			console.log(res.data);
			setResearchersData(res.data);

		}).catch(function(error){
				console.log(error);
		});
	};
	
	function loadResearchersByAllProjects(){
		AccountProjectService.getByFilter({'account_type':'Researcher'}).then(function(res){
			console.log(res.data);
			var _accounts=res.data;
			var _projects={};
			for(var i=0;i<_accounts.length;i++){
				console.log(_accounts[i].account.id);
				if(typeof _projects[_accounts[i].account.id]=="undefined"){
					_projects[_accounts[i].account.id]=[];
				}
				_projects[_accounts[i].account.id].push(_accounts[i].project);
			}
			console.log(_projects);
			setResearchersProjectsData(_projects);
			//setResearchersData(res.data);

		}).catch(function(error){
				console.log(error);
		});
	};

	
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
							//not implemented	
						
						}else{
							//load project if set 
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								loadProjectById(project_id);
							}
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
								if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
									loadResearchersByProject(project_id);
									loadAccountInvitesByProjectId(project_id);
								}else{
									loadAllResearchers();
									loadResearchersByAllProjects();
								}
								
							}else if(account_type=='Researcher'){//Researcher
								loadAllResearchers();
								loadResearchersByAllProjects();
							}
						}
					}
				}	
		}
	}, []);
	
	function loadProjectById(project_id){
		ProjectService.getById(project_id).then(function(res){
		setProjectData(res.data);
		console.log(res.data);
		setProjectFound(true);
		}).catch(function(error){
			console.log(error);
			setProjectFound(false);
		});
	}
	
	const [accountInviteData,setAccountInviteData]=useState([]);
	function loadAccountInvitesByProjectId(project_id){
		AccountInvite.getByFilter({'project':project_id,'account_type':'Researcher','invited_by':AuthService.getCurrentUser()._id}).then(function(res){
			console.log(res.data);
			setAccountInviteData(res.data);

		}).catch(function(error){
				console.log(error);
		});
	};
	
	//new researcher project invite  
	const [openNewInviteModal, setNewInviteModalOpen] = useState(false);
		  
	const handleNewInviteModalOpen = () => {
		setNewInviteModalOpen(true);
	};

	const handleNewInviteModalClose = () => {
		setNewInviteModalOpen(false);
	};
	
	const [ShowErrorMessage,setErrorStatus]=useState(false); 
	const [ErrorText,setErrorMessage]=useState(""); 
	
	const [ShowSuccessMessage,setSuccessStatus]=useState(false); 
	const [SuccessText,setSuccessMessage]=useState("");
		  
	const handleNewResearcherInviteModalOpen = () => {
		setNewInviteModalOpen(true);
				setErrorStatus(false);
		setErrorMessage("");
		setSuccessStatus(false);
		setSuccessMessage("");
	};
	
	const [firstName,setFirstName]=useState('');
	const handleFirstNameChange = (event) => {
		setFirstName(event.target.value);
	};
	const [lastName,setLastName]=useState('');
	const handleLastNameChange = (event) => {
		setLastName(event.target.value);
	};
	const [email,setEmail]=useState('');
	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	
	const handleSubmitNewInvite=(event: React.FormEvent<HTMLFormElement>) => {
				setErrorStatus(false);
		setErrorMessage("");
		setSuccessStatus(false);
		setSuccessMessage("");
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		console.log(data);
		var invite={'first_name':data.get('first_name'),
					'last_name':data.get('last_name'),
					'email':data.get('email'),
					'invited_by':AuthService.getCurrentUser()._id,
					'project':project_id,
					'account_type':'Researcher'
		};
		console.log('invite',invite);
		if((invite.first_name!=="") && (invite.last_name!=="") && (validateEmail(invite.email))){
				console.log('valid',invite);
				AccountInvite.create(invite).then(function(res){
					console.log(res.data);
					setSuccessStatus(true);
					setSuccessMessage("Invite sent");
					
					setFirstName('');
					setLastName('');
					setEmail('');

				}).catch(function(error){
					console.log(error);
					setErrorStatus(true);
		setErrorMessage(error.response.data.message);

				});
		}else{
			console.log('invalid',invite);
			setErrorStatus(true);
			setErrorMessage("Please ensure all required fields are completed correctly!!");
		}
		
		
		/*if((project.name)&&(project.description)){	
			//createProject(project);
			//setNewProjectModalOpen(false);		
		}else{
			//setErrorStatus(true);
			//setErrorMessage("Please ensure all required fields are completed correctly!!");
		}*/
	};
	
	function sendAccountInviteRequest(request){

			AccountInvite.create(request).then(function(res){
				console.log(res.data);

			}).catch(function(error){
				console.log(error);

			});
	}

  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
		{/* error no project found */}
	{(!projectFound) ?
		<>
		
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
		 <Typography color="text.primary">Researchers</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
		
		
		</>
	:<></>}
	{/* if project is found */}
	{(projectFound) ?
	<>
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>
		 <Typography color="text.primary">Researchers</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		
	
	</>
	:<></>}
	
	{(projectFound) ?
	<>
	
	
	<div style={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
         // bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <Button onClick={handleNewResearcherInviteModalOpen}   variant="outlined" startIcon={<EmailIcon/>}>
		  Send Invite
		</Button>
		 {/* manage project settings dialog */}
		<Dialog
        open={openNewInviteModal}
      >
	  <Box component="form" onSubmit={handleSubmitNewInvite}  noValidate sx={{ mt: 1 ,width:'500px'}}>
        <DialogTitle>New Invite</DialogTitle>
        <DialogContent>
          <DialogContentText>
      
          </DialogContentText>
		  { ShowErrorMessage &&
				<Alert variant="filled" severity="error">{ErrorText}</Alert>
			} 
			{ ShowSuccessMessage &&
				<Alert variant="filled" severity="success">{SuccessText}</Alert>
			}
		  
          <TextField
				  margin="normal"
				  required
				  fullWidth
				  id="first_name"
				  label="First Name"
				  name="first_name"
				  value={firstName}
				  onChange={handleFirstNameChange}
				/>
		  <br/>
		   <TextField
				  margin="normal"
				  required
				  fullWidth
				  id="last_name"
				  label="Last Name"
				  name="last_name"
				  value={lastName}
				   onChange={handleLastNameChange}
				/>
		  <br/>
		   <TextField
				  margin="normal"
				  required
				  fullWidth
				  id="email"
				  label="Email"
				  name="email"
				  autoComplete="email"
				  value={email}
				   onChange={handleEmailChange}
				/>
		  <br/>
			
				
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNewInviteModalClose}   variant="outlined">Close</Button>
		  <Button endIcon={<EmailIcon/>} type="submit"   variant="outlined">Send</Button>
        </DialogActions>
		</Box>
      </Dialog>

      </Box>

    </div>
	</>
	:<></>}

	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
		{(projectFound) ?
		<>
		
		</>
		:<>
		
		</>}
		
		{/* test table */}
		
		{/*	  <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Dessert (100g serving)</TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="left">Protein&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>*/}
		<br/><br/>
		{/* end test table */}
		
			
			{(researcherAcountsData.length==0) ?
					<>None found!
					</>
				:
				<>
				<TableContainer component={Paper}>
				  <Table sx={{ minWidth: 650,width:'90%'}} aria-label="simple table">
					<TableHead>
					<TableRow>
						<TableCell>First Name</TableCell>
						<TableCell>Last Name</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>Last Logged In</TableCell>
						<TableCell></TableCell>
					  </TableRow>
					</TableHead>
					   <TableBody>
						{researcherAcountsData.map((row,index) => (
						<TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						  <TableCell align="left">{row.first_name}</TableCell>
						  <TableCell align="left">{row.last_name}</TableCell>
						  <TableCell align="left">{row.email}</TableCell>
						  <TableCell align="left">{formatDate(row.last_logged_in)}</TableCell>
						  <TableCell align="left">
						 
						 {/*{researcherProjectsData[row.id].map((project,index) => (
								<>{project.name}</>
						))}*/}
						  
						  </TableCell>
					
						</TableRow>
						))}
				  
					</TableBody>
					</Table>
				</TableContainer>
				</>
				}
				
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
		{(projectFound) ?
		<>
		
		</>
		:<>
		
		</>}
	
			{(researcherAcountsData.length==0) ?
				<>None found!</>
				:<>
				<TableContainer component={Paper}>
				  <Table sx={{ minWidth: 650,width:'90%'}} aria-label="simple table">
					<TableHead>
					<TableRow>
						<TableCell>First Name</TableCell>
						<TableCell>Last Name</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>Last Logged In</TableCell>
					  </TableRow>
					</TableHead>
					   <TableBody>
						{researcherAcountsData.map((row,index) => (
						<TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						  <TableCell align="left">{row.first_name}</TableCell>
						  <TableCell align="left">{row.last_name}</TableCell>
						  <TableCell align="left">{row.email}</TableCell>
						  <TableCell align="left">{formatDate(row.last_logged_in)}</TableCell>
				
						</TableRow>
						))}
				  
					</TableBody>
					</Table>
				</TableContainer>
				</>
				}
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Researchers;