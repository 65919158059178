import {useEffect,useState,useRef} from 'react';
import { useNavigate,useParams } from "react-router-dom";


import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'
import ExportsService from '../service/Exports'
import ProjectService from '../service/Project'


const Exports = () => {
	
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	const [projectLoaded,setProjectLoaded]=useState(false);
	
	//const [tableFound,setTableFound]=useState(false);
	//const [tableName,setTableName]=useState("");
	//let params = useParams();
	//let table =params.table;

	//console.log('table',table);
	
	let food_filter={};
	
	const [exportTables,setExportTables]=useState([
		{'table':'foods','title':'Foods','options':[
			
		]},{'table':'clients','title':'Clients','options':[

		]},{'table':'client_log','title':'Client Logs','options':[

		]},{'table':'client_log_daily_totals','title':'Client Logs Daily Totals','options':[

		]},{'table':'client_log_daily_averages','title':'Client Logs Daily Averages','options':[

		]}
	]);
	
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
								
						
						}else{
							if(typeof table!=="undefined"){
								setTableFound(true);
								setTableName(table);
							}
							
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								loadProjectById(project_id);
								food_filter={'global':false,'project':project_id};
							}else{
								food_filter={'global':true};
							}
							
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
							
								
							}else if(account_type=='Researcher'){//Researcher
								
							}
						}
					}
				}	
		}
	}, []);
	
	
	function loadProjectById(project_id){
			ProjectService.getById(project_id).then(function(res){
				setProjectData(res.data);
				console.log(res.data);
				setProjectFound(true);
				setProjectLoaded(true);
			}).catch(function(error){
					console.log(error);
					setProjectFound(false);
			});
		}
	
	const [downloadUrl,setDownloadUrl]=useState("");
	const stateRef = useRef();
	stateRef.loaded = false;
	
	const handleTestDownload = (e,row) => {
		console.log('row',row.table);
		var _table =row.table;
		var _filter={};
		if(_table=='foods'){
			
			if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
				_filter={'global':false,'approved':true,'project':project_id};
			}else{
				_filter={'global':true,'approved':true};
			}
		}
		
		if((_table=='clients')||(_table=='client_log')||(_table=='client_log_daily_totals')||(_table=='client_log_daily_averages')){
			
			if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
				_filter={'project':project_id};
			}else{
				_filter={};
			}
		}
		
		
		console.log('_filter',_filter);
		ExportsService.build(_table,_filter).then(function(res){
			console.log(res.data);
			var filename =res.data.filename;
			var url=ExportsService.getUrl(filename);
			console.log('url',url);
			setDownloadUrl(url);
			
			var counter=0;
			var refreshIntervalId = setInterval(function(){
			checkIfBuilt(filename);
			if(stateRef.loaded){
				clearInterval(refreshIntervalId);
				window.open(url,'_blank');}
				if(counter==20){
					clearInterval(refreshIntervalId);
				}
				counter++;
			}, 1000);
						
		}).catch(function(error){
				console.log(error);
		});
	  };
	  
	  function checkIfBuilt(filename){
		  	ExportsService.exists(filename).then(function(res){
			if(res.data.exists){
				stateRef.loaded=true;
			}
		}).catch(function(error){
				console.log(error);
		});
	  }

  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
	<br/>
	{(projectFound) ?
	<>
	<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/"> Home </Link>
		<Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>

		<Typography color="text.primary">Exports</Typography>
      </Breadcrumbs>
	
	</>
	:<>
	<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/"> Home </Link>
		<Typography color="text.primary">Export</Typography>
      </Breadcrumbs>
	</>}
	  
	  <br/>
	  <Divider />
		<br/>
	  
	  
	  
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
	{/*  tables */}
	  <br/> <br/>
	  <Grid container spacing={3}>
		{exportTables.map((row,index) => (
         <Grid key={row.table} item item xs={12} sm={6} md={4}>
			<Card variant="outlined" sx={{ minWidth: 25 }}>
			<CardHeader style={{ textAlign: 'center' }} 
       
				  
					title={row.title}
						
				  />
				<CardContent>
					<Typography variant="h5" component="div">
					
					</Typography>
					<Typography sx={{ mb: 1.5 }} color="text.secondary">
			  
					</Typography>
					<Typography variant="body2">
					
						<br />
					</Typography>
				</CardContent>
				<CardActions>
				{/*
					<Button onClick={(e) => handleTestDownload(e,row)} color="primary" variant="outlined"  endIcon={<FileDownloadIcon />}   size="small" >
					Download csv
				</Button>*/}
					
					{/*<a href={downloadUrl}> Download Here </a>*/}
				</CardActions>
			</Card>	
		</Grid>))}
	</Grid>
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Exports;