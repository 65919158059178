import {useEffect,useState} from 'react';
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import TopPublicNavigation from '../components/TopPublicNavigation.jsx'

//config
var Config = require('../config.json');
const formatJson=function(json){
	return JSON.stringify(json,undefined, 4);
}

const defaultTheme = createTheme();

const Info = () => {
		const [currentConfig,setCurrentConfig]=useState({});
	useEffect(() => {
		setCurrentConfig(Config);
	}, []);
	
  return(
  
   <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
		 <TopPublicNavigation Linked={true} LinkTo={'/login'} />
          <Typography component="h1" variant="h4">
		  App Info
          </Typography>
		  	 
          <Box component="form" noValidate sx={{ mt: 1 }}>
		 
            <Grid container>
              <Grid item xs>
               <pre>
		{formatJson(currentConfig)}
		</pre>
              </Grid>
              <Grid item>
                
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Info;