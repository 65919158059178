import {useEffect,useState} from 'react';
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'

const formatDate=function(d){
	var d = new Date(d);
	Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}
    var dformat = [
               d.getDate().padLeft(),
			   (d.getMonth()+1).padLeft(),
               d.getFullYear()].join('/') +' ' +
              [d.getHours().padLeft(),
               d.getMinutes().padLeft()].join(':');
	return dformat;
}

const Account = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
							//not implemented	
						
						}else{
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
							
								
							}else if(account_type=='Researcher'){//Researcher
								
							}
						}
					}
				}	
		}
	}, []);
	

	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
	<br/>

		
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
		 <Typography color="text.primary">My Account</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
		
		<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650,width:'80%'}} aria-label="simple table">
        <TableHead>
		<TableRow>
            <TableCell>Account Type</TableCell>
            <TableCell align="left">{currentUser.__t}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell align="left">{currentUser.first_name}</TableCell>
          </TableRow>
		   <TableRow>
            <TableCell>Last Name</TableCell>
            <TableCell align="left">{currentUser.last_name}</TableCell>
          </TableRow>
		  <TableRow>
            <TableCell>Email</TableCell>
            <TableCell align="left">{currentUser.email}</TableCell>
          </TableRow>
		   <TableRow>
            <TableCell>Expiry Date</TableCell>
            <TableCell align="left">{formatDate(currentUser.expiry_date)}</TableCell>
          </TableRow>
        </TableHead>
		{/*   <TableBody>
  
            <TableRow
              
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
               
              </TableCell>
              <TableCell align="left">
				
			  
			  
			  </TableCell>
			  
            </TableRow>
          
</TableBody>*/}
      </Table>
    </TableContainer>

	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Account;